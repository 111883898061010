import { useCallback, useContext, useState, createContext } from "react";
import _uniqueId from "lodash/uniqueId";
import ToastNotification from "../components/Toast/Toast";

const ToastContext = createContext();

export default ToastContext;

export function ToastContextProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    function (newToast) {
      setToasts((toasts) => [
        ...toasts,
        { ...newToast, id: _uniqueId("toast-") },
      ]);
    },
    [setToasts]
  );

  const delToast = (id) =>
    setToasts((toasts) => [...toasts.filter((t) => t.id !== id)]);

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className="toasts-wrapper">
        {toasts.map((toastProps) => (
          <ToastNotification
            {...toastProps}
            key={toastProps.id}
            onClose={() => delToast(toastProps.id)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  );
}
