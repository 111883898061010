import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  if (props.profile) {
    return await getAllByProfileId(props);
  }
  return await axios.get(
    `${API_URL}/users?limit=${props.countItems}&page=${props.currentPage}&sortField=${props.sortField}&sortType=${props.sortType}`,
    { headers: authHeader() }
  );
};

const getAllByProfile = async (props) => {
  const headers = authHeader();
  return (
    Object.keys(headers).length &&
    (await axios.get(
      `${API_URL}/users?limit=${props.countItems}&page=${props.currentPage}&profile=${props.profile}&type=profiles`,
      { headers }
    ))
  );
};

const getAllByProfileId = async (props) => {
  return await axios.get(
    `${API_URL}/users?limit=${props.countItems}&page=${props.currentPage}&profileId=${props.profile}`,
    { headers: authHeader() }
  );
};

const getUserById = async (id) => {
  return await axios.get(`${API_URL}/users/${id}`, { headers: authHeader() });
};

const create = (
  profile_id,
  first_name,
  last_name,
  user_name,
  email,
  password
) => {
  return axios
    .post(`${API_URL}/users`, {
      profile_id,
      first_name,
      last_name,
      user_name,
      email,
      password,
    })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const edit = (
  id,
  profile_id,
  first_name,
  last_name,
  user_name,
  email,
  password
) => {
  return axios
    .put(
      `${API_URL}/users`,
      {
        id,
        profile_id,
        first_name,
        last_name,
        user_name,
        email,
        password,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const changePassword = (id, password) => {
  return axios
    .put(
      `${API_URL}/users/password`,
      { id, password },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const del = (id) => {
  return axios
    .post(`${API_URL}/users/delete`, { id }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

export default {
  getAll,
  getAllByProfile,
  getAllByProfileId,
  create,
  getUserById,
  edit,
  changePassword,
  del,
};
