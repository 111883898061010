import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import List from "components/Table/List.js";
import ProfileService from "../../services/profile.service";
import AuthService from "../../services/auth.service";
import { useToast } from "../../hooks";
import { updateLocalStorageCache } from "utils/Service";

const headSort = [
  { text: "Id", field: "id", model: null },
  { text: "Nombre", field: "name", model: null },
  { text: "Descripción", field: "description", model: null },
  { text: "", field: null, model: null },
];

const ProfilesList = (props) => {
  const [body, setBody] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    model: null,
    field: "id",
    type: "ASC",
  });
  const addToast = useToast();

  const handleSort = (model, field, type) => {
    setCurrentSort({ model, field, type });
  };

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("PERFILES");
    setCurrentPermissions(permissions);
    const params = {
      sortField: currentSort?.field,
      sortType: currentSort?.type,
    };
    ProfileService.getAll(params).then((response) => {
      if (response.data.count > 0) {
        setBody(response.data.response);
      }
      setTotalItems(response.data.count);
    });
  }, [currentSort]);

  const handleDelete = (id, e) => {
    e.preventDefault();
    const del = window.confirm("¿Eliminar perfil?");
    if (del) {
      ProfileService.del(id).then(() => {
        let list = body.filter((data) => data.id !== parseInt(id));
        setBody(list);
        addToast({
          variant: "success",
          header: "Perfiles",
          body: "Se eliminó el perfil correctamente",
        });
        updateLocalStorageCache();
      });
    }
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasUpdate ? (
          <a
            href={`/admin/profiles/${props.id}/edit`}
            className="btn-simple btn btn-xs btn-info"
          >
            <i className="fas fa-edit"></i>
          </a>
        ) : null}

        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <button
            onClick={(e) => handleDelete(props.id, e)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </button>
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <Container fluid>
        <Button
          href="/admin/profiles/create"
          className="btn-fill btn btn-primary capitalize"
        >
          Nuevo Perfil
        </Button>
        <br />
        <br />
        <List
          thead={headSort}
          tbody={body}
          totalItems={totalItems}
          actions={actions}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
      </Container>
    </div>
  );
};

export default ProfilesList;
