export const headSellsList = [
  { text: "Opp ID", field: "opportunity_id", model: null },
  { text: "Dominio", field: "plate_number", model: "ns_sell_file" },
  { text: "Stock ID", field: "stock_id", model: "ns_sell_file" },
  { text: "Estado NS", field: "category_id", model: "ns_sell_file" },
  { text: "Estado", field: "status_id", model: null },
  { text: "Proveedor", field: "financing_provider", model: "ns_sell_file" },
  { text: "Fecha Recep Venta", field: "createdAt", model: null },
  { text: "Fecha de entrega", field: "delivery_date", model: "ns_sell_file" },
  { text: "Tipo de entrega", field: "delivery_type", model: "ns_sell_file" },
  { text: "Cliente", field: "business_name", model: "main_owner" },
  {
    text: "Fecha de firma 08",
    field: "updatedAt",
    model: "purchase_file_form,forms_status_history",
  },
  {
    text: "Escribania",
    field: "notary_office_id",
    model: "purchase_file_form",
  },
  { text: "Semana entrega", field: "delivery_date", model: "ns_sell_file" },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  { text: "Fecha suspension", field: "updatedAt", model: "sell_status_pause" },
  { text: "BACS", field: "id", model: "ns_sell_file,bacs_purchase" },
  { text: "", field: null, model: null },
];

export const headFolio = [
  { text: "Opp ID", field: "opportunity_id", model: null },
  { text: "Dominio", field: "plate_number", model: "ns_sell_file" },
  { text: "Stock ID", field: "stock_id", model: "ns_sell_file" },
  { text: "Estado", field: "status_id", model: null },
  { text: "Proveedor", field: "financing_provider", model: "ns_sell_file" },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  { text: "Semana entrega", field: "delivery_date", model: "ns_sell_file" },
  { text: "Tipo de entrega", field: "delivery_type", model: "ns_sell_file" },
  {
    text: "Tipo de operación",
    field: "transaction_type_id",
    model: "ns_sell_file,purchase",
  },
  { text: "Fecha folio", field: "updatedAt", model: "sell_status_folio" },
  { text: "Persona Responsable", field: "person_in_charge_id", model: null },
  { text: "Fecha suspensión", field: "updatedAt", model: "sell_status_pause" },
  {
    text: "Fecha observado",
    field: "updatedAt",
    model: "sell_status_observed",
  },
  { text: "BACS", field: "id", model: "ns_sell_file,bacs_purchase" },
  {
    text: "BAC Desafectado",
    field: "status_id",
    model: "ns_sell_file,bacs_purchase",
  },
  { text: "", field: null, model: null },
];

export const headPresentation = [
  { text: "Opp ID", field: "opportunity_id", model: null },
  { text: "Dominio", field: "plate_number", model: "ns_sell_file" },
  { text: "Stock ID", field: "stock_id", model: "ns_sell_file" },
  { text: "Estado", field: "status_id", model: null },
  { text: "Proveedor", field: "financing_provider", model: "ns_sell_file" },
  { text: "Tipo de entrega", field: "delivery_type", model: "ns_sell_file" },
  {
    text: "Registro donde se Presenta",
    field: "issuer_entity_tentative_id",
    model: null,
  },
  { text: "Zona", field: "zone", model: "issuer_entity_tentative" },
  { text: "Gestor que lo presenta", field: "agent_id", model: null }, // no
  { text: "Entrega a logística", field: "waiting_date", model: null },
  { text: "Persona Responsable", field: "person_in_charge_id", model: null },
  { text: "Fecha turno presentación", field: "turn_request_date", model: null },
  { text: "Fecha Real presentación", field: "presented_date", model: null },
  { text: "Fecha turno retiro", field: "turn_take_date", model: null },
  { text: "Fecha real retiro", field: "retired_date", model: null },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  { text: "BACS", field: "id", model: "ns_sell_file,bacs_purchase" },
  { text: "Adjuntos", field: null, model: null },
  { text: "", field: null, model: null },
];

export const headPresentationAgent = [
  { text: "Opp ID", field: "opportunity_id", model: null },
  { text: "Dominio", field: "plate_number", model: "ns_sell_file" },
  { text: "Estado", field: "status_id", model: null },
  { text: "Tipo de entrega", field: "delivery_type", model: "ns_sell_file" },
  {
    text: "Registro donde se Presenta",
    field: "issuer_entity_tentative_id",
    model: null,
  },
  { text: "Zona", field: "zone", model: "issuer_entity_tentative" },
  { text: "Entrega a logística", field: "waiting_date", model: null },
  { text: "Persona Responsable", field: "person_in_charge_id", model: null },
  { text: "Fecha turno presentación", field: "turn_request_date", model: null },
  { text: "Fecha Real presentación", field: "presented_date", model: null },
  { text: "Fecha turno retiro", field: "turn_take_date", model: null },
  { text: "Fecha real retiro", field: "retired_date", model: null },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  { text: "BACS", field: "id", model: "ns_sell_file,bacs_purchase" },
  { text: "", field: null, model: null },
];

export const headGeneral = [
  { text: "Opp ID", field: "opportunity_id", model: null },
  { text: "Dominio", field: "plate_number", model: "ns_sell_file" },
  { text: "Stock ID", field: "stock_id", model: "ns_sell_file" },
  { text: "Estado NS", field: "category_id", model: "ns_sell_file" },
  { text: "Proveedor", field: "financing_provider", model: "ns_sell_file" },
  { text: "Año entrega", field: "delivery_date", model: "ns_sell_file" },
  { text: "Mes entrega", field: "delivery_date", model: "ns_sell_file" },
  { text: "Semana entrega", field: "delivery_date", model: "ns_sell_file" },
  { text: "Fecha entrega", field: "delivery_date", model: "ns_sell_file" },
  {
    text: "Fecha Firma 08",
    field: "updatedAt",
    model: "purchase_file_form,forms_status_history",
  },
  {
    text: "Fecha Recepción 08",
    field: "updatedAt",
    model: "purchase_file_form,forms_08_received",
  },
  { text: "Fecha recepción prenda", field: "financed_date", model: null },
  {
    text: "Fecha confección tramite",
    field: "updatedAt",
    model: "sell_status_folio",
  },
  {
    text: "Entrega a Logística",
    field: "updatedAt",
    model: "presentation_waiting",
  },
  {
    text: "Fecha real presentación",
    field: "updatedAt",
    model: "presentation_presented",
  },
  {
    text: "Fecha real retiro",
    field: "updatedAt",
    model: "presentation_retired",
  },
  {
    text: "Fecha CAT Recibido",
    field: "updatedAt",
    model: "presentation_cat_received",
  },
  {
    text: "Fecha Cédula Recibida",
    field: "take_licence_date",
    model: null,
  },
  { text: "Estado", field: "status_id", model: null },
  {
    text: "Fecha estimada de entrega",
    field: "estimated_delivery_date",
    model: null,
  },
  {
    text: "Fecha fin periodo de prueba",
    fields: null,
    model: null,
  },
  { text: "Persona Responsable", field: "person_in_charge_id", model: null },
  { text: "Fecha suspensión", field: null, model: null },
  {
    text: "Fecha observado",
    field: "updatedAt",
    model: "sell_status_observed",
  },
  { text: "Días activos", field: null, model: null },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  // { text: "Días Transcurridos", field: null, model: null },
  { text: "BACS", field: "id", model: "ns_sell_file,bacs_purchase" },
  {
    text: "BAC Desafectado",
    field: "status_id",
    model: "ns_sell_file,bacs_purchase",
  },
  { text: "Adjuntos", field: null, model: null },
  { text: "", field: null, model: null },
];

export const headSuspended = [
  { text: "Opp ID", field: "opportunity_id", model: null },
  { text: "Dominio", field: "plate_number", model: "ns_sell_file" },
  { text: "Stock ID", field: "stock_id", model: "ns_sell_file" },
  { text: "Estado", field: "status_id", model: null },
  { text: "Año", field: "year", model: null },
  { text: "Mes", field: "month", model: null },
  { text: "Semana", field: "week", model: null },
  { text: "Fecha entrega", field: "delivery_date", model: "ns_sell_file" },
  {
    text: "Fecha estimada de entrega",
    field: "estimated_delivery_date",
    model: null,
  },
  {
    text: "Fecha fin periodo de prueba",
    fields: null,
    model: null,
  },
  { text: "Fecha suspension", field: "suspended_date", model: null },
  { text: "Persona Responsable", field: "person_in_charge_id", model: null },
  { text: "Dias suspendidos", field: "suspended_period", model: null },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  { text: "Fecha Manual", field: "past_suspended_date", model: null },
  { text: "BACS", field: "id", model: "ns_sell_file,bacs_purchase" },
  { text: "", field: null, model: null },
];

export const headRejected = [
  { text: "Opp ID", field: "opportunity_id", model: null },
  { text: "Dominio", field: "plate_number", model: "ns_sell_file" },
  { text: "Stock ID", field: "stock_id", model: "ns_sell_file" },
  { text: "Fecha Recep Venta", field: "createdAt", model: null },
  { text: "Fecha rechazo", field: "updatedAt", model: null },
  {
    text: "Radicación",
    field: "description",
    model: "issuer_entity_tentative",
  },
  { text: "Zona", field: "zone", model: "issuer_entity_tentative" },
  { text: "Empleado", field: "updatedUser", model: null },
  {
    text: "Motivo de rechazo",
    field: "description",
    model: "sell_file_cancel_reason",
  },
  { text: "BACS", field: "id", model: "ns_sell_file,bacs_purchase" },
  { text: "", field: null, model: null },
];

export const headFinanced = [
  { text: "Opp ID", field: "opportunity_id", model: null },
  { text: "Dominio", field: "plate_number", model: "ns_sell_file" },
  { text: "Stock ID", field: "stock_id", model: "ns_sell_file" },
  { text: "Estado", field: null, model: null },
  { text: "Proveedor", field: "financing_provider", model: "ns_sell_file" },
  { text: "Importe Prestamo", field: "financing_amount", model: null },
  {
    text: "Fecha Firma 08",
    field: "updatedAt",
    model: "purchase_file_form,forms_status_history",
  },
  { text: "Recepción Prenda", field: "financed_date", model: null },
  {
    text: "Presentación Real",
    field: "updatedAt",
    model: "presentation_presented",
  },
  { text: "Cédula Recibida", field: "take_licence_date", model: null },
  {
    text: "Última Mod. Estado",
    field: "updatedAt",
    model: "sell_financed,sell_financed_history",
  },
  { text: "Última Mod.", field: "updatedAt", model: "sell_financed" },
  { text: "Estimada", field: "estimated_date", model: "sell_financed" },
  { text: "Responsable", field: "person_in_charge_id", model: "sell_financed" },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  { text: "BACS", field: "id", model: "ns_sell_file,bacs_purchase" },
  {
    text: "BAC Desafectado",
    field: "status_id",
    model: "ns_sell_file,bacs_purchase",
  },
  { text: "", field: null, model: null },
];
