const Footer = () => (
  <footer className="footer">
    <nav className="pull-left"></nav>
    <p className="copyright pull-right">
      &copy; {new Date().getFullYear()} KAVAK (v
      {localStorage.getItem("version") || ""})
    </p>
  </footer>
);

export default Footer;
