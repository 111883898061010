import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";


const HistoryStatus = (props) => {
  const [information, setInformation] = useState(false);

  useEffect(() => {
    setInformation(props.data);
  }, [props.data]);

  return (
    <div className="plate-information" style={{ width: "100%" }}>
      <Card
        ctTableFullWidth
        ctTableResponsive
        title={props.title}
        content={
          props.loading ? (
            "Loading..."
          ) : (
            <Table striped hover>
              <thead>
                <tr>
                  <td>Fecha</td>
                  <td>Estado</td>
                  <td>Usuario</td>
                  <td>Perfil</td>
                </tr>
              </thead>
              <tbody>
                {information && information && information?.length
                  ? information.map((c,index) => (
                      <tr key={index}>
                        <td>{c.updatedAt}</td>
                        <td>{c.status}</td>
                        <td>{c.updatedUser ?? ""}</td>
                        <td>{c.profile ?? ""}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          )
        }
      />
    </div>
  );
};

export default HistoryStatus;
