import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Filters from "../../components/Filters";
import Assigment from "../../components/Modal/StatusForm08/assigment";
import CancelContent from "../../components/Modal/StatusForm08/cancel";
import EditContent from "../../components/Modal/StatusForm08/content";
import PrintForm from "../../components/Modal/PrintForms/printForms";
import List from "../../components/Table/List";
import ExcelButton from "../../components/ExcelButton";
import Modal from "../../layouts/ModalDisplay";
import AuthService from "../../services/auth.service";
import StatusForm08Service from "../../services/form08/statusForm08.service";
import UploadService from "../../services/upload.service";
import {
  badgeGeneric,
  badgeGenericBoolean,
  diffDateBadge,
  parseDiffDate,
  // parseRemaining,
  parseStatus08,
  status08Badge,
} from "../../utils/Badges";
import { downloadAllZip } from "../../utils/File";
import {
  constants,
  fileSections,
  serviceTypes,
  status08,
} from "../../variables/constants";
import styles from "../General/styles.module.scss";
import { useFilters, useGrid, useStatusModal, useToast } from "../../hooks/";
import { filtersStatusForm08 } from "./constants/constants_filter";
import { headSortAdmin, headSortNotary } from "./constants/constants_columns";
import notaryService from "../../services/notary.service";

const finalStatusText = [status08.CANCELED.text, status08.REMOVED.text];

const finalStatusValues = [status08.CANCELED.value, status08.REMOVED.value];

const StatusForm08List = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [profile, setProfile] = useState(null);

  const [dataModal, setDataModal] = useState(null);

  const [showAssigment, setShowAssigment] = useState(false);
  const [dataAssigment, setDataAssigment] = useState(null);

  const [showPrinForm, setShowPrintForm] = useState(false);
  const [dataPrintForm, setDataPrintForm] = useState(null);

  const addToast = useToast();

  const {
    handleCloseEditModal,
    showEditModal,
    handleOpenEditModal,
    showRejectModal,
    handleShowRejectModal,
    handleCloseRejectModal,
  } = useStatusModal();

  const next = {
    link: "/admin/management/toma-vehiculo",
    text: "Toma",
  };

  const selectFilters = () => {
    const local_profile = JSON.parse(
      localStorage.getItem("user")
    ).data.user.profile.name.toLowerCase();
    if (local_profile === constants.notaryProfile) {
      filtersStatusForm08.notaryOffice.hide = true;
    }
    return filtersStatusForm08;
  };

  const {
    body,
    cleanPage,
    countItems,
    currentPage,
    currentSort,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleBody,
    handleTotalPage,
    totalItems,
    totalPage,
    refresh,
    refreshGrid,
  } = useGrid({
    model: null,
    field: "id",
    type: "ASC",
  });

  const { filters, onFilterChange } = useFilters(selectFilters(), cleanPage);
  const [emptyFilters, setEmptyFilters] = useState(true);

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("ESTADOS_08");
    setCurrentPermissions(permissions);
    // Para obtener el perfil y la escribania asociada si corresponde
    const user = JSON.parse(localStorage.getItem("user")) || null;
    const local_profile = user.data.user.profile.name.toLowerCase();

    if (local_profile === constants.notaryProfile) {
      notaryService
        .getAllUsers({
          notary_office_id: null,
          user_id: user.data.user.id,
        })
        .then((response) => {
          if (response.data.success && response.data.response.length > 0) {
            filters.notaryOffice.value =
              response.data.response[0].notary_office.id;
          }
          setProfile(local_profile);
        })
        .catch((error) => {
          console.error(error);
          setProfile(local_profile);
        });
    } else {
      setProfile(local_profile);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleImages = (data) =>
      data.imagenes > 0 ? (
        <Button
          onClick={(e) => handleDownload(data, e)}
          className="btn-simple btn btn-xs btn-secondary"
        >
          <i className="fas fa-download" aria-hidden="true"></i>
          {data.imagenes}
        </Button>
      ) : null;

    const sortColumnsAdmin = (body) =>
      body.map((e) => ({
        id: e.id,
        form_number: e.form_number,
        vehicle_id: e.vehicle_id, // handleVehicleId(e),
        opportunity_id: e.opportunity_id,
        bacs_id: e.bacs_id,
        plate_number: e.plate_number,
        form_type: e.form_type,
        current_status: status08Badge(e.current_status),
        signed_date: e.signed_date,
        delivered_date: e.delivered_date,
        notary_office: e.notary_office,
        received_date: e.received_date,
        observation: e.cancel_reason ? (
          <>
            {badgeGeneric(
              finalStatusValues.includes(e.current_status) ? "danger" : "info",
              e.cancel_reason
            )}
            <br />
            {e.observation}
          </>
        ) : (
          e.observation
        ),
        legalization_date: e.legalization_date,
        withdrawal_legalization_date: e.withdrawal_legalization_date,
        send_certification_date: e.send_certification_date,
        received_certification_date: e.received_certification_date,
        enterprise_signature: badgeGenericBoolean(e.enterprise_signature),
        aging: diffDateBadge(e.aging),
        diff_days: e.diff_days,
        week_reception_date: e.week_reception_date,
        _certificate: e.certificate,
        imagenes: handleImages(e),
      }));

    const sortColumnsNotary = (body) =>
      body.map((e) => ({
        id: e.id,
        form_number: e.form_number,
        vehicle_id: e.vehicle_id,
        opportunity_id: e.opportunity_id,
        bacs_id: e.bacs_id,
        plate_number: e.plate_number,
        form_type: e.form_type,
        current_status: status08Badge(e.current_status),
        signed_date: e.signed_date,
        delivered_date: e.delivered_date,
        received_date: e.received_date,
        observation: e.cancel_reason ? (
          <>
            {badgeGeneric(
              finalStatusValues.includes(e.current_status) ? "danger" : "info",
              e.cancel_reason
            )}
            <br />
            {e.observation}
          </>
        ) : (
          e.observation
        ),
        // remaining_time: parseRemaining(e.remaining_time),
        enterprise_signature: badgeGenericBoolean(e.enterprise_signature),
        aging: diffDateBadge(e.aging),
        diff_days: e.diff_days,
        week_reception_date: e.week_reception_date,
        _certificate: e.certificate,
        imagenes: handleImages(e),
      }));

    const params = {
      page: currentPage,
      limit: countItems,
      filters,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
    };

    const emptyFilters = Object.keys(params.filters).every(
      (key) => 
        params.filters[key].value === "" || 
        params.filters[key].value === null || 
        params.filters[key].value?.length === 0
    );

    setEmptyFilters(emptyFilters);
    if (profile) {
      if(emptyFilters && profile !== constants.notaryProfile) {
        handleTotalItems(0);
        handleTotalPage(0);
        handleBody(sortColumnsAdmin([]));
        return
      }
      StatusForm08Service.getAll(params).then((response) => {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        const arraydata = parseDiffDate(parseStatus08(response.data.response));
        if (profile !== constants.notaryProfile) {
          handleBody(sortColumnsAdmin(arraydata));
        } else {
          handleBody(sortColumnsNotary(arraydata));
        }
      });
    }
    //eslint-disable-next-line
  }, [currentPage, currentSort, refresh, filters, profile]);

  const handleShowModal = (id, e) => {
    e.preventDefault();
    StatusForm08Service.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  const handleShowModalAssigment = () => {
    StatusForm08Service.getLastNumber().then((response) => {
      if (response.data.success) {
        setDataAssigment(response.data.data ?? 0);
        setShowAssigment(true);
      }
    });
  };

  const handleDownload = (data, e) => {
    e.preventDefault();
    const params = {
      countItems: 999,
      currentPage: 0,
      vehicleId: data.vehicle_id,
      plateNumber: data.plate_number,
      section: fileSections.form08,
    };
    UploadService.download(params).then((response) => {
      if (response.data.success) {
        downloadAllZip(response.data, data.plate_number);
      }
    });
  };

  const handleDelete = (params) => {
    const rejectedBy = Object.entries(status08).find(
      (s) => s[1].cancelReasonId === parseInt(params.cancel_reason_id, 10)
    );
    const payload = {
      ...params,
      current_status: rejectedBy
        ? rejectedBy[1].value
        : status08.CANCELED.value,
      vehicle_id: null,
      opportunity_id: null,
      bacs_id: null,
      form_number: null,
    };
    if (payload.current_status !== status08.REMOVED.value) {
      delete payload.form_number;
    }

    StatusForm08Service.edit(payload).then((response) => {
      if (response.success) {
        addToast({
          variant: "success",
          header: "Formularios 08",
          body: "Se ha anulado el formulario correctamente",
        });
        refreshGrid();
      }
    });
  };

  const handleShowModalReject = (id, e) => {
    e.preventDefault();
    handleShowRejectModal(id);
  };

  const handleShowModalPrintForm = (id, e) => {
    e.preventDefault();
    setDataPrintForm({ id });
    setShowPrintForm(true);
  };

  const handleCloseModalAssigment = () => setShowAssigment(false);
  const handleCloseModalPrintForm = () => setShowPrintForm(false);

  const actions = (props) => {
    return !finalStatusText.includes(props.current_status.props.children) ? (
      <>
        {currentPermissions && currentPermissions.permissions.hasUpdate ? (
          <Button
            onClick={(e) => handleShowModal(props.id, e)}
            className="btn-simple btn btn-xs btn-secondary"
          >
            <i className="fas fa-file-alt" aria-hidden="true"></i>
          </Button>
        ) : null}
        {currentPermissions &&
        currentPermissions.permissions.hasSelect &&
        props._certificate ? (
          <Button
            onClick={(e) => handleShowModalPrintForm(props.vehicle_id, e)}
            className="btn-simple btn btn-xs btn-info"
          >
            <i className="fas fa-print" aria-hidden="true"></i>
          </Button>
        ) : null}
        {currentPermissions &&
        currentPermissions.permissions.hasDelete &&
        status08.COMPLETED.text !== props.current_status.props.children ? (
          <Button
            onClick={(e) => handleShowModalReject(props.id, e)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </Button>
        ) : null}
      </>
    ) : null;
  };

  return (
    <div className="content">
      <Container fluid>
        <div className={`content-filters form-group ${styles.lessHeight}`}>
          <Button
            className="btn-fill btn btn-primary capitalize"
            onClick={() => {
              handleShowModalAssigment();
            }}
          >
            Asignar Nuevos Formularios
          </Button>
          <div className={styles.export}>
            <ExcelButton type="forms08" filters={filters} />
          </div>
        </div>
        <Filters filterFields={filters} onFilterChange={onFilterChange} />
        <List
          thead={
            profile !== constants.notaryProfile ? headSortAdmin : headSortNotary
          }
          tbody={body}
          actions={actions}
          next={profile !== constants.notaryProfile ? next : false}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
          emptyFilters={emptyFilters}
        />
        {showEditModal && (
          <Modal
            content={
              <EditContent
                data={dataModal}
                handleClose={handleCloseEditModal}
                setRefresh={refreshGrid}
              />
            }
            showModal={showEditModal}
            handleShow={handleShowModal}
            handleClose={handleCloseEditModal}
          />
        )}
        {showAssigment && (
          <Modal
            content={
              <Assigment
                data={dataAssigment}
                handleClose={handleCloseModalAssigment}
                setRefresh={refreshGrid}
              />
            }
            showModal={showAssigment}
            handleShow={handleShowModalAssigment}
            handleClose={handleCloseModalAssigment}
            headerTitle="Asignación de formularios 08 en lote"
          />
        )}
        {showPrinForm && (
          <Modal
            content={
              <PrintForm
                data={dataPrintForm}
                handleClose={handleCloseModalPrintForm}
                functionId={78}
              />
            }
            showModal={showPrinForm}
            handleShow={handleShowModalPrintForm}
            handleClose={handleCloseModalPrintForm}
            headerTitle="Impresión de formularios"
          />
        )}
        {showRejectModal.show && (
          <Modal
            content={
              <CancelContent
                data={showRejectModal.id}
                handleDelete={handleDelete}
                handleClose={handleCloseRejectModal}
              />
            }
            showModal={showRejectModal.show}
            handleShow={handleShowModalReject}
            handleClose={handleCloseRejectModal}
          />
        )}
      </Container>
    </div>
  );
};

export default StatusForm08List;
