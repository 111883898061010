import React from 'react';

const UserContext = React.createContext({
    email: "",
    name: "",
    profile: "",
    isRolAdmin: false,
    active: false
});

export default UserContext;