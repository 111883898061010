import React, { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import Form from "react-validation/build/form";
import { Multiselect } from "multiselect-react-dropdown";

import sellsService from "../../../../services/sells/sells.service";
import sellsPresentationService from "../../../../services/sells/sellsPresentation.service";
import uploadService from "../../../../services/upload.service";
import sellRequirementsService from "../../../../services/sells/requirements.service";
import { LogisticInfo, Presentation } from "./components";
import ConfirmationAlert from "../../../../components/ConfirmationAlert/confirmationAlert";
import CustomAccordion from "../../../../components/Accordion";
import SeeForms from "../../../../components/CheckForms";
import { Comments } from "../../../../components/Comments/";
import {
  DatePickerField,
  MultiSelectField,
  SelectField,
} from "../../../../components/Form";
import Requirement from "../../../../components/RequirementCheck";
import Dropdown from "../../../../components/Table/Dropdown";
import disabledSubmit from "./utils/disabledSubmit";
import { processFiles } from "../../../../utils/File";
import { sellEmployees, sellRequirements } from "../../../../utils/Service";
import {
  agentProfiles,
  constants,
  delivery_types,
  fileSections,
  sells_status,
  sells_status_select,
} from "../../../../variables/constants";
import { useConfirmationAlert, useToast } from "../../../../hooks/";

const observedStatus = [
  sells_status.PRESENTATION_PRESENTED.value,
  sells_status.PRESENTATION_OBSERVED_PRESENTED.value,
  sells_status.PRESENTATION_CAT_RECEIVED.value,
];

const requirementStatus = [
  sells_status.PRESENTATION_OBSERVED.value,
  sells_status.PRESENTATION_OBSERVED_RETIRED.value,
];

// Pasos para organizar el modal, visualmente
const step1 = [
  sells_status.PRESENTATION_WAITING.value,
  sells_status.PRESENTATION_DELIVERED.value,
];

const step2 = [sells_status.PRESENTATION_PRESENTED.value];

const step3 = [
  sells_status.PRESENTATION_OBSERVED.value,
  sells_status.PRESENTATION_OBSERVED_RETIRED.value,
  sells_status.PRESENTATION_OBSERVED_DELIVERED.value,
];

const step4 = [sells_status.PRESENTATION_OBSERVED_PRESENTED.value];

const stepFinal = [sells_status.PRESENTATION_RETIRED.value];

const allowedProfile = [
  constants.adminProfile,
  constants.sellsBackofficeProfile,
];

const Content = (props) => {
  const {
    data: {
      response: {
        opportunity_id,
        purchase_issuer_entity,
        status,
        issuer_entity_tentative_id,
        issuer_entity_tentative,
        zone,
        distance,
        loss_request,
        status_id,
        agent_id,
        turn_request_date,
        turn_rerequest_date,
        turn_request_schedule,
        turn_take_date,
        turn_take_schedule,
        extraordinary_date,
        // take_licence_date,
        // delivered_date,
        presented_date,
        observed_date,
        // obs_delivered_date,
        obs_presented_date,
        cat_date,
        retired_date,
        agent_presented, //solo nombre
        agent_observed, //solo nombre
        plate_number,
        comments,
        diff_days,
        diff_days_presentation,
        stock_id,
        suspended_days,
        person_in_charge_id,
        estimated_end_trial,
        suspended_date,
        delivery_type,
        rendition_link_presented,
        rendition_link_retired,
      },
    },
    profile,
    agentOptions,
    handleUpdate,
    handleClose,
    refresh,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    plate_number,
    status_id,
    agent_id,
    issuer_entity_tentative_id,
    loss_request,
    turn_request_date:
      turn_request_date === null ? null : new Date(turn_request_date),
    turn_take_date: turn_take_date === null ? null : new Date(turn_take_date),
    turn_rerequest_date:
      turn_rerequest_date === null ? null : new Date(turn_rerequest_date),
    turn_request_schedule:
      turn_request_schedule === null ? null : new Date(turn_request_schedule),
    turn_take_schedule:
      turn_take_schedule === null ? null : new Date(turn_take_schedule),
    extraordinary_date:
      extraordinary_date === null ? null : new Date(extraordinary_date),
    images: [],
    comment: null,
    comment_reason_id: null,
    stock_id,
    person_in_charge_id,
    rendition_link_presented,
    rendition_link_retired,
  };

  const constantData = {
    diff_days,
    diff_days_presentation,
    current_location: purchase_issuer_entity,
    suspended_days,
  };

  // Siguiente estado
  const nextStatus = sells_status[sells_status[status].next];

  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState();
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [turnRequestScheduleDisabled, setTurnRequestScheduleDisabled] =
    useState(true);
  const [turnTakeScheduleDisabled, setTurnTakeScheduleDisabled] =
    useState(true);

  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const [forms, setForms] = useState([]);
  const [currentRequirements, setCurrentRequirements] = useState([]);
  const [totalRequirements, setTotalRequirements] = useState();
  const addToast = useToast();

  // En Periodo de prueba
  const on_trial =
    delivery_types.filter((t) => t.trial).includes(delivery_type) &&
    +new Date(estimated_end_trial) > +new Date() &&
    !suspended_date;

  // Habilitacion de campos
  const turn_request_date_disabled =
    (!step1.includes(status_id) && turn_request_date !== null) || on_trial;
  const turn_rerequest_date_disabled =
    !step3.includes(status_id) && turn_rerequest_date !== null;
  const turn_take_date_disabled = stepFinal.includes(status_id);

  const saveChanges = (name, selectedItem) => {
    onSimpleFieldChange(name, selectedItem ? selectedItem.value : null);
  };

  const updateRequirements = (id, status) => {
    const idx = currentRequirements.findIndex((r) => r.requirement_id === id);
    currentRequirements[idx].status = status;
    setTotalRequirements(currentRequirements.filter((r) => r.status).length);
  };

  const getReasons = async () => {
    try {
      const response = await sellsService.getAllReasons();
      if (response.status === 200) {
        setReasons(
          response.data.response.filter((r) => !r.text.includes("[SUSPENDER]"))
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnSelected = (selectedItem) => {
    setSelectedIssuer([selectedItem]);
  };

  useEffect(() => {
    getReasons();
    setSelectedIssuer(
      issuer_entity_tentative_id === null
        ? null
        : [
            {
              value: issuer_entity_tentative_id,
              text: issuer_entity_tentative,
            },
          ]
    );
    if (agent_id !== null) {
      setSelectedAgent(
        agentOptions.find((a) => a.value === agent_id)?.text ?? null
      );
    }
    sellsPresentationService.getSellsForms(opportunity_id).then((response) => {
      if (response.data.success) {
        setForms(response.data.response.sort((x) => x.code));
      }
    });

    sellRequirementsService
      .getAllByOpportunity(opportunity_id, "observed")
      .then((response) => {
        if (response.data.success) {
          setCurrentRequirements(response.data.response);
          setTotalRequirements(
            response.data.response.filter((r) => r.status).length
          );
        }
      });

    // eslint-disable-next-line
  }, []);

  // Al presionar un botón
  useEffect(() => {
    // Habilitacion de campos schedule, Presentación y Retiro
    setTurnRequestScheduleDisabled(
      fields.comment_reason_id !== constants.schedule_allow_comment_reason_id ||
        turn_request_date === null ||
        presented_date !== null
    );

    setTurnTakeScheduleDisabled(
      fields.comment_reason_id !== constants.schedule_allow_comment_reason_id ||
        turn_take_date === null ||
        retired_date !== null
    );

    // Condiciones para el submit - Cambio de estado
    const {
      conditionOnCat,
      conditionOnDelivery,
      conditionOnObserved,
      conditionOnObservedDelivery,
      conditionOnPresented,
      conditionOnWaiting,
    } = disabledSubmit({
      status: status_id,
      fields,
      step2,
      step4,
      profile,
      allRequirements: currentRequirements.length === totalRequirements,
      on_trial,
    });

    setAllowSubmit(
      conditionOnWaiting ||
        conditionOnDelivery ||
        conditionOnPresented ||
        conditionOnCat ||
        conditionOnObserved ||
        conditionOnObservedDelivery
    );
    if (submitAction) {
      submit();
      setSubmitAction(false);
      handleClose();
    }
    // eslint-disable-next-line
  }, [
    submitAction,
    selectedAgent,
    fields,
    profile,
    status_id,
    handleUpdate,
    handleClose,
    turn_request_date,
    presented_date,
    turn_take_date,
    retired_date,
    totalRequirements,
  ]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handleFiles = async (e) => {
    const newImages = await processFiles(e.target.files);
    setFields({
      ...fields,
      observation: true,
      images: [...newImages],
    });
  };

  const submit = () => {
    const dataOpportunity = {
      ...fields,
      opportunity_id,
    };

    const images = {
      opportunity_id: opportunity_id,
      vehicle_id: null,
      plate_number: fields.plate_number,
      section: fileSections.presentation,
      images: [...fields.images],
    };

    delete dataOpportunity.images;

    const arrPromises = [];

    if (fields.images.length > 0) {
      const promise_images = new Promise((resolve, reject) => {
        uploadService.save(images).then((response) => {
          if (response.success) {
            resolve("Se guardaron los documentos");
          }
          reject("Fallo el guardado de documentos");
        });
      });
      arrPromises.push(promise_images);
    }

    const promise_opportunity = new Promise((resolve, reject) => {
      sellsPresentationService
        .update(opportunity_id, dataOpportunity)
        .then((response) => {
          if (response.status === 200) {
            resolve("Se actualizaron los datos");
          } else {
            reject("Fallo la actualización de los datos");
          }
        });
    });
    arrPromises.push(promise_opportunity);

    // Si estoy observando y cargo requisitos
    if (
      requirementStatus.includes(fields.status_id) &&
      fields.requirements?.length
    ) {
      const promise_requirements = new Promise((resolve, reject) => {
        sellRequirementsService
          .insert(opportunity_id, fields.requirements)
          .then((response) => {
            if (response.success) {
              resolve("Se crearon los requisitos");
            } else {
              reject("Fallo la creación de los requisitos");
            }
          });
      });

      arrPromises.push(promise_requirements);
    }

    Promise.all(arrPromises)
      .then((values) => {
        values.map((v) =>
          addToast({
            variant: "success",
            header: "Ventas - Presentación",
            body: v,
          })
        );
        refresh();
        handleClose();
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "Ventas - Presentación",
          body: reason,
        });
        refresh();
      });
  };

  const handlePreConfirmation = (status) => {
    const statusName = sells_status_select.filter((x) => x.value === status)[0]
      .text;
    handleConfirmationModal(statusName, () => handleChangeStatus(status));
  };

  const handleChangeStatus = (status) => {
    onSimpleFieldChange("status_id", status);
    setSubmitAction(true);
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <LogisticInfo
            fields={fields}
            onSimpleFieldChange={onSimpleFieldChange}
            profile={profile}
            constantData={constantData}
          />

          <div className="row">
            {!agentProfiles.includes(profile) ? (
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.agentPresenting}</strong>
                </label>
                <div>
                  {!stepFinal.includes(status_id) ? ( // chequear que si el id no exista, puede volver a seleccionar otro
                    <Dropdown
                      className="form-control"
                      name="agent"
                      placeholder={constants.agentPresenting}
                      onSelect={(e) => {
                        onSimpleFieldChange(
                          "agent_id",
                          e.target.value === constants.dropdownDefault
                            ? null
                            : e.target.value
                        );
                        setSelectedAgent(
                          e.target.options[e.target.selectedIndex].text
                        );
                      }}
                      selected={fields.agent_id}
                      options={agentOptions}
                    />
                  ) : (
                    agentOptions.filter((x) => (x.agen_id = fields.agent_id))[0]
                      .text
                  )}
                </div>
              </div>
            ) : null}
            <div className="col-6">
              <label className="capitalize">
                <strong>Registro donde se Presenta</strong>
              </label>
              {stepFinal.includes(status_id) ||
              agentProfiles.includes(profile) ? (
                <div>{issuer_entity_tentative}</div>
              ) : (
                <MultiSelectField
                  name={"issuer_entity_tentative_id"}
                  selectedValues={selectedIssuer}
                  saveChanges={saveChanges}
                  handleOnSelected={handleOnSelected}
                />
              )}
            </div>
            {!agentProfiles.includes(profile) && (
              <div className="col">
                <DatePickerField
                  label={"Fecha Extraordinaria"}
                  selected={fields.extraordinary_date}
                  onChange={(date) =>
                    onSimpleFieldChange("extraordinary_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                  disabled={stepFinal.includes(status_id)}
                />
              </div>
            )}
            {allowedProfile.includes(profile) ? (
              <SelectField
                label={"Persona responsable"}
                name={"person_in_charge_id"}
                onChange={(e) =>
                  onSimpleFieldChange(
                    "person_in_charge_id",
                    !!e.target.value ? parseInt(e.target.value, 10) : null
                  )
                }
                value={fields.person_in_charge_id}
                options={sellEmployees()}
              />
            ) : null}
          </div>

          {issuer_entity_tentative_id !== null &&
          !agentProfiles.includes(profile) ? (
            <div className="row">
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.zone}</strong>
                </label>
                <div>{zone}</div>
              </div>
              <div className="col">
                <label className="capitalize">
                  <strong>Distancia</strong>
                </label>
                <div>{distance}</div>
              </div>
            </div>
          ) : null}

          <hr />
          <Presentation
            agentPresented={agent_presented}
            fields={fields}
            onSimpleFieldChange={onSimpleFieldChange}
            status={status_id}
            step1={step1}
            turnRequestScheduleDisabled={turnRequestScheduleDisabled}
            turn_request_date_disabled={turn_request_date_disabled}
            presented_date={presented_date}
            turn_request_date={turn_request_date}
          />

          {step1.includes(status_id) &&
          agentProfiles.includes(profile) ? null : (
            <>
              <hr />
              <div className="row">
                <div className="col-12">
                  <label className="capitalize">
                    <strong>Retiro</strong>
                  </label>
                </div>
                <div className="col-3">
                  <DatePickerField
                    selected={fields.turn_take_date}
                    onChange={(date) =>
                      onSimpleFieldChange("turn_take_date", date)
                    }
                    dateFormat="dd/MM/yyyy"
                    disabled={turn_take_date_disabled}
                    label={"Fecha Turno"}
                  />
                </div>
                <div
                  className={`col-3${turn_take_date === null ? " hidden" : ""}`}
                >
                  <DatePickerField
                    selected={fields.turn_take_schedule}
                    onChange={(date) =>
                      onSimpleFieldChange("turn_take_schedule", date)
                    }
                    dateFormat="dd/MM/yyyy"
                    disabled={turnTakeScheduleDisabled}
                    label={constants.reschedule}
                  />
                </div>
                <div
                  className={`col-3${
                    !stepFinal.includes(status_id) ? " hidden" : ""
                  }`}
                >
                  <DatePickerField
                    selected={
                      retired_date == null ? null : new Date(retired_date)
                    }
                    dateFormat="dd/MM/yyyy"
                    disabled
                    label={"Fecha Real"}
                  />
                </div>
                <div className="col-6">
                  <div className="col">
                    <label className="capitalize">
                      <strong>Link Rendición</strong>
                    </label>
                    <div>
                      <input
                        type="url"
                        className="form-control"
                        name="rendition_link_retired"
                        autoComplete="false"
                        onChange={(e) =>
                          onSimpleFieldChange(
                            "rendition_link_retired",
                            e.target.value
                          )
                        }
                        value={fields.rendition_link_retired}
                        maxLength={200}
                        disabled={!stepFinal.includes(nextStatus.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <hr />
          {!agentProfiles.includes(profile) ? (
            <div className="row">
              <div
                className={`col-6${
                  step1.concat(step2).includes(status_id) ||
                  agent_observed == null
                    ? " hidden"
                    : ""
                }`}
              >
                <DatePickerField
                  selected={
                    observed_date == null ? null : new Date(observed_date)
                  }
                  dateFormat="dd/MM/yyyy"
                  disabled
                  label={constants.observedDate}
                />
              </div>
              <div
                className={`col-6${
                  step1.concat(step2).includes(status_id) ||
                  agent_observed == null
                    ? " hidden"
                    : ""
                }`}
              >
                <label className="capitalize">
                  <strong>{constants.observedAgent}</strong>
                </label>
                <div>{agent_observed != null ? agent_observed : ""}</div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div
              className={`col-6${
                step1.concat(step2).includes(status_id) ||
                agent_observed == null
                  ? " hidden"
                  : ""
              }`}
            >
              <DatePickerField
                selected={fields.turn_rerequest_date}
                onChange={(date) =>
                  onSimpleFieldChange("turn_rerequest_date", date)
                }
                dateFormat="dd/MM/yyyy"
                disabled={turn_rerequest_date_disabled}
                label={constants.turnReRequestDate}
              />
            </div>
            <div
              className={`col-6${
                step1.concat(step2, step3).includes(status_id) ||
                agent_observed == null
                  ? " hidden"
                  : ""
              }`}
            >
              <DatePickerField
                selected={
                  obs_presented_date == null
                    ? null
                    : new Date(obs_presented_date)
                }
                dateFormat="dd/MM/yyyy"
                disabled
                label={constants.realObservedPresentedDate}
              />
            </div>
          </div>
          <div className="row">
            <div className={`col-6${cat_date == null ? " hidden" : ""}`}>
              <DatePickerField
                selected={cat_date == null ? null : new Date(cat_date)}
                dateFormat="dd/MM/yyyy"
                disabled
                label={constants.receptionCatDate}
              />
            </div>
          </div>
          <SeeForms forms={forms} />
          <br />
          <Comments
            reasons={reasons}
            comments={comments}
            statusList={sells_status}
            onChangeSelect={(e) =>
              onSimpleFieldChange("comment_reason_id", e.target.value)
            }
            onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
            hideComments={!agentProfiles.includes(profile)}
            hideReasons={
              constants.agentExternalProfile !== profile ? false : true
            }
          />
          {observedStatus.includes(status_id) ? (
            <div className="row">
              <div className="col">
                <label className="capitalize">
                  <strong>Requisitos pendientes</strong>
                </label>
                <Multiselect
                  options={sellRequirements()}
                  onSelect={(selectedItem) => {
                    onSimpleFieldChange("requirements", selectedItem);
                  }}
                  onRemove={(selectedItem) => {
                    onSimpleFieldChange("requirements", selectedItem);
                  }}
                  displayValue="text"
                  placeholder="Requisitos"
                  closeIcon="cancel"
                  selectionLimit="-1"
                  showCheckbox={true}
                  singleSelect={false}
                />
              </div>
            </div>
          ) : null}
          {requirementStatus.includes(status_id) ? (
            <>
              <CustomAccordion
                title={`Requisitos Pendientes (${totalRequirements}/${currentRequirements.length})`}
              >
                <Stack
                  direction="horizontal"
                  gap={3}
                  className="d-flex flex-wrap"
                >
                  {currentRequirements.map((c) => (
                    <div>
                      <Requirement
                        id={opportunity_id}
                        data={c}
                        onChange={updateRequirements}
                        serviceName={sellRequirementsService.updateOne}
                      />
                    </div>
                  ))}
                </Stack>
              </CustomAccordion>
              <div className="row">
                <div className="col">
                  <label className="capitalize">
                    <strong>Requisitos Adicionales</strong>
                  </label>
                  <Multiselect
                    options={sellRequirements().filter(
                      (r) =>
                        !currentRequirements
                          .map((c) => c.requirement_id)
                          .includes(r.value)
                    )}
                    onSelect={(selectedItem) => {
                      onSimpleFieldChange("requirements", selectedItem);
                    }}
                    onRemove={(selectedItem) => {
                      onSimpleFieldChange("requirements", selectedItem);
                    }}
                    displayValue="text"
                    placeholder="Requisitos"
                    closeIcon="cancel"
                    selectionLimit="-1"
                    showCheckbox={true}
                    singleSelect={false}
                  />
                </div>
              </div>
            </>
          ) : null}
          {!stepFinal.includes(status_id) && agentProfiles.includes(profile) ? (
            <>
              <div className="row">
                <div className="col">
                  <label className="capitalize">
                    <strong>{constants.files}</strong>
                  </label>
                  <div>
                    <span>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        name="images"
                        encType="multipart/form-data"
                        multiple={true}
                        onChange={handleFiles}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>

        <br />

        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-secundary btn-sm capitalize"
              onClick={submit}
            >
              Actualizar
            </Button>
          </div>
          {observedStatus.includes(status_id) ? (
            <div>
              <Button
                className="btn-fill pull-right btn btn-danger btn-sm capitalize"
                onClick={() =>
                  handlePreConfirmation(
                    sells_status.PRESENTATION_OBSERVED.value
                  )
                }
                disabled={
                  /*(agentProfiles.includes(profile) &&
                  fields.images.length === 0) ||*/
                  !fields.comment?.length ||
                  fields.comment == null ||
                  fields.comment_reason_id == null ||
                  fields.comment_reason_id === constants.dropdownDefault ||
                  !fields.requirements?.length
                }
              >
                {sells_status.PRESENTATION_OBSERVED.text.substr(15)}
              </Button>
            </div>
          ) : null}
          {status_id === sells_status.PRESENTATION_OBSERVED.value ? (
            <div>
              <Button
                className="btn-fill pull-right btn btn-danger btn-sm capitalize"
                onClick={() =>
                  handlePreConfirmation(
                    sells_status.PRESENTATION_OBSERVED_RETIRED.value
                  )
                }
              >
                {sells_status.PRESENTATION_OBSERVED_RETIRED.text.substr(15)}
              </Button>
            </div>
          ) : null}
          {stepFinal.includes(status_id) ||
          status_id === sells_status.PRESENTATION_SUSPENDED.value ? null : (
            <div className="row">
              <Button
                className="btn-fill pull-right btn btn-warning btn-sm capitalize"
                onClick={() => handlePreConfirmation(nextStatus.value)}
                disabled={!allowSubmit}
              >
                {nextStatus.text.substr(15)}
              </Button>
            </div>
          )}
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Content;
