import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import HistoryStatus from "./historyStatus";
import Information from "./information";
import ManagementService from "../../services/management.service";
import GeneralService from "../../services/general.service";
import { statusBadge, status08Badge } from "../../utils/Badges";
import { constants, purchaseStatus } from "../../variables/constants";
import ModalDisplay from "../../layouts/ModalDisplay";
import InquiryEdit from "../../components/Modal/Purchase/Inquiry";
import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";
import { useConfirmationAlert, useToast } from "../../hooks";


const profileAllowEdit = [constants.adminProfile, constants.coordinatorProfile];

const Inquiry = () => {
  const [info, setInfo] = useState(false);
  const [infoHistory, setInfoHistory] = useState(false);
  const [info08History, setInfo08History] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(Date.now());
  const [profile, setProfile] = useState(null);

  const [domains, setDomains] = useState([]);
  const [open, setOpen] = useState(false);

  // const [vehicleId, setVehicleId] = useState(null);
  const { confirmationModal, handleConfirmationModal, handleCloseConfirmationModal } = useConfirmationAlert();
  const addToast = useToast();

  useEffect(() => {
    if (info) {
      getInquiry(info.vehicle_id);
    }
    //eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || null;
    setProfile(user.data.user.profile.name.toLowerCase());
  }, []);

  const refreshGrid = () => {
    setRefresh(new Date());
  };

  const openModal = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const getInquiry = (id) => {
    setLoading(true);
    GeneralService.getInquiryById(id).then((response) => {
      const { history, form08History, ...rest } = response.data.response;
      setInfo(rest);
      setInfoHistory(
        history.map((h) => ({
          ...h,
          status: statusBadge(h.history_status),
        }))
      );
      setInfo08History(
        form08History.map((h) => ({
          ...h,
          status: status08Badge(h.current_status),
        }))
      );
      setLoading(false);
    });
  }

  // Selector de emisor
  const onSelect = (selectedList, selectedItem) => {
    getInquiry(selectedItem.vehicle_id);
  };

  // Eliminación del emisor
  const onRemove = () => {
    // setVehicleId(null);
    setDomains([]);
    setInfo(false);
    setInfoHistory(false);
    setInfo08History(false);
  };

  // Buscador del emisor
  const onSearch = (word) => {
    if (word.length > 1) {
      const params = { limit: 1000, search: word };
      ManagementService.getAllDomains(params).then((response) => {
        let rows = [];
        if (response.data.success && response.data.count > 0) {
          rows = response.data.response.map((data) => ({
            vehicle_id: data.vehicle_id,
            name: `${data.vehicle_id} - ${data.plate_number} - ${data.brand} ${
              data.vehicle_version
            } - ${data.model_year} - ${
              data.holders.length > 0
                ? data.holders.map((h) => h.holder_name).join("/")
                : "sin dueños"
            }`,
          }));
        }
        setDomains(rows);
      });
    }
  };

  const onDismissed = () => {
    const payload = {
      vehicle_id: info.vehicle_id,
      purchase_file_status: purchaseStatus.DISMISSED_KAVAK.value,
    };
    
    ManagementService.edit(payload).then((response) => {
      if (response.success) {
        addToast({
          variant: "success",
          header: "Toma - Consulta",
          body: "Se desestimó el tramite correctamente",
        });
        refreshGrid();
      }
    });

    handleCloseConfirmationModal()
  }

  return (
    <div className="content content-plate-validation">
      <Container fluid>
        <div
          className="form-group"
          style={{ marginRight: "20px", marginBottom: 10 }}
        >
          <Multiselect
            name="search"
            options={domains}
            onSelect={onSelect}
            onRemove={onRemove}
            onSearch={onSearch}
            displayValue="name"
            placeholder="Seleccione un dominio"
            closeIcon="cancel"
            selectionLimit="1"
            showCheckbox={false}
            singleSelect={false}
          />
        </div>
        {profileAllowEdit.includes(profile) && domains.length > 0 ? (
          <div style={{ paddingBottom: 10 }}>
            <Button onClick={openModal}>Actualizar información</Button>
            {info && info.status === purchaseStatus.COMPLETED.value ? (
              <Button style={{ marginLeft: 8 }} variant="danger" onClick={
                () => handleConfirmationModal(
                  "Desestimado KAVAK",
                  onDismissed,
                  "¿Desea cambiar el estado?",
                  "Este registro ya fue completado. Al confirmar, se agregará un estado"
                )
              }>Desestimar</Button>
            ) : null}
          </div>
        ) : null}
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
        <div className="content-information">
          <Information data={info} loading={loading} />
        </div>
        <div className="content-information">
          <HistoryStatus
            data={infoHistory}
            title="Toma - Cambios Históricos de Estados"
            loading={loading}
          />
        </div>
        <div className="content-information">
          <HistoryStatus
            data={info08History}
            title="Formulario 08 - Cambios Históricos de Estados"
            loading={loading}
          />
        </div>
      </Container>

      {open ? (
        <ModalDisplay
          content={
            <InquiryEdit
              handleClose={onClose}
              vehicle_id={info.vehicle_id}
              data={info}
              refresh={refreshGrid}
            />
          }
          showModal={open}
          handleShow={openModal}
          handleClose={onClose}
          headerTitle={
            <span style={{ fontWeight: "bold" }}>Actualizar ventas</span>
          }
        />
      ) : null}
    </div>
  );
};

export default Inquiry;
