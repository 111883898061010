import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Find from "../../components/Table/SearchInput";
import List from "../../components/Table/List.js";
import Dropdown from "../../components/Table/Dropdown";
import IssuerEntityService from '../../services/issuerEntity.service';
import AuthService from "../../services/auth.service";
import { constants } from "../../variables/constants";
import { zones } from "../../utils/Service";

const headSort = [
  { text: 'Id', field: 'issuer_entity_id', model: null },
  { text: 'Descripción', field: 'description', model: null },
  { text: 'Dirección', field: 'address', model: null },
  { text: 'Zona', field: 'zone', model: null },
  { text: '', field: null, model: null },
];

const sortColumns = (body) => (
  body.map(e => ({
    issuer_entity_id: e.issuer_entity_id,
    description: e.description,
    address: e.address_complete,
    zone: e.zone,
  }))
);

const IssuerEntityList = (props) => {
  const [body, setBody] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState(null);

  // ZONAS
  const [zone, setZone] = useState(null);
  const handleSearchZone = (e) => {
    setZone(e.target.value === constants.dropdownDefault ? null : e.target.value);
    setCurrentPage(0);
  };

  // Busqueda de registros
  const [searchq, setSearchq] = useState(null);
  const handleSearch = (e) => {
    if (e.target.value.length > 3) {
      setSearchq(e.target.value);
    } else {
      setSearchq(null);
    }
    setCurrentPage(0);
  };

  //PAGINATE
  const countItems = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentSort, setCurrentSort] = useState({ model: null, field: 'issuer_entity_id', type: 'ASC' });

  const handleToPage = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const handleSort = (model, field, type) => {
    setCurrentSort({ model, field, type });
    setCurrentPage(0);
  }

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      searchq,
      zone,
      sortField: currentSort?.field,
      sortType: currentSort?.type
    };
    IssuerEntityService.getAll(params).then(response => {
      if (response.data.count > 0) {
        setTotalItems(response.data.totalItems);
        const result = Math.ceil(response.data.totalItems / countItems);
        setTotalPage(result);
        let arraydata = response.data.response;
        arraydata = sortColumns(arraydata);
        setBody(arraydata);
      }
    });
  }, [zone, searchq, currentPage, currentSort]);

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("REGISTROS");
    setCurrentPermissions(permissions);
  }, []);

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasUpdate
          ? <a href={`/admin/issuer-entity/${props.issuer_entity_id}/edit`} className="btn-simple btn btn-xs btn-info"><i className="fas fa-edit"></i></a>
          : null
        }
      </>
    );
  };

  return (
    <div className="content">
      <Container fluid>
        <div className="content-filters row"  style={{marginBottom: 10}}>
          <div className="col-sm-3">
            <Find
              name="description"
              placeholder="Descripción"
              onTyping={handleSearch}
            />
          </div>
          <div className="col-sm-3">
            <Dropdown
              className="form-control"
              name="zone"
              placeholder="Seleccione una zona"
              options={zones()}
              onSelect={handleSearchZone}
            />
          </div>
        </div>
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
      </Container>
    </div>
  );
};

export default IssuerEntityList;
