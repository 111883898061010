import PrintService from "../services/print/print.service";
import { form08Frente, form08Dorso } from "./forms/form08";
import { form02 } from "./forms/form02";
import { form04 } from "./forms/form04";
import { printForms } from "./Service";

export const formPDF = (data, dataVehicle, form, place = "1", functionId) => {
  const payload = {
    plate_number: dataVehicle.plate_number,
    reception_place_id: place,
    form_id: form,
    function_id: functionId,
  };
  PrintService.logPrint(payload)
    .then((response) => console.log("Print Log Success"))
    .catch((error) => console.log("Print Log Failed", error));

  const formDescription = printForms().find((f) => f.value === form).text;

  switch (form) {
    // 08-Compra-Frente
    case 1:
    // 08-Bacs-Frente
    case 2:
    // 08-Compra-Consigna-Frente
    case 3: {
      form08Frente(data, dataVehicle, formDescription, place);
      break;
    }
    // 08-Compra-Dorso
    case 4:
    // 08-Compra-Consigna-Dorso
    case 5: {
      form08Dorso(dataVehicle, formDescription, place);
      break;
    }
    // 04-Frente
    case 6: {
      form04(dataVehicle, formDescription, place);
      break;
    }
    // 02-Frente
    case 7: {
      form02(dataVehicle, formDescription, place);
      break;
    }

    default:
      break;
  }
};

export default {};
