import jsPDF from "jspdf";
import { receptionPlaces } from "utils/Service";

export const form04 = (dataVehicle, form, place) => {
  const placeName = receptionPlaces().find((r) => r.value === place).text;
  const doc04 = new jsPDF({
    orientation: "p",
    unit: "cm",
    format: [21, 29.7], //medida 08 en pixeles = 20.3 cm x 30.5cm
  });
  doc04.setFont("helvetica");
  doc04.setFontSize(10);
  doc04.text(10, 4.6, dataVehicle.plate_number);
  doc04.text(9, 20.8, dataVehicle.plate_number);
  doc04.text(6.5, 21.5, dataVehicle.car.brand);
  doc04.text(5.5, 22.1, dataVehicle.car.type);
  doc04.text(6, 22.8, dataVehicle.car.model);
  doc04.text(7, 23.4, dataVehicle.engine.engineBrand);
  doc04.text(7, 24, dataVehicle.engine.engineNumber);
  doc04.text(6.5, 24.8, dataVehicle.engine.chasisBrand);
  doc04.text(7, 25.4, dataVehicle.engine.chasisNumber);
  doc04.save(`${placeName}-formulario-${form}-${dataVehicle.plate_number}.pdf`);
};

export default {};
