import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { genericBadge } from "../../../utils/Badges";
import { sells_status } from "../../../variables/constants";
import { Comments } from "../../../components/Comments";

const Information = (props) => {
  const [information, setInformation] = useState(false);

  useEffect(() => {
    setInformation(props.data);
  }, [props.data]);

  return (
    <div className="plate-information" style={{ width: "100%" }}>
      <Card
        ctTableFullWidth
        ctTableResponsive
        title="Información"
        content={
          props.loading ? (
            "Loading..."
          ) : (
            <Table striped hover>
              <tbody>
                <tr>
                  <td>
                    <strong>Oportunidad ID</strong>
                  </td>
                  <td>
                    {information?.opportunity_id
                      ? information.opportunity_id
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Dominio</strong>
                  </td>
                  <td>
                    {information?.plate_number ? information.plate_number : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Stock ID</strong>
                  </td>
                  <td>{information?.stock_id ? information.stock_id : "-"}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Estado</strong>
                  </td>
                  <td>
                    {information?.status
                      ? genericBadge(sells_status, information.status)
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Fecha Recepción de Cédula</strong>
                  </td>
                  <td>{information?.take_licence_date ?? "-"}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Titular</strong>
                  </td>
                  <td>
                    {information.owner && information.owner.length
                      ? `${information?.owner[0].business_name} - DNI ${information?.owner[0].dni} [${information?.owner[0].email}]`
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Tipo de Dirección</strong>
                  </td>
                  <td>
                    {information && information.address_type
                      ? information.address_type
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Dirección</strong>
                  </td>
                  <td>
                    {information && information.address
                      ? information.address
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Fecha de Documentación</strong>
                  </td>
                  <td>
                    {information && information.withdraw_date
                      ? information.withdraw_date
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Número de Seguimiento</strong>
                  </td>
                  <td>
                    {information && information.tracking_number
                      ? information.tracking_number
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Comments
                      comments={information.comments}
                      statusList={sells_status}
                      hideReasons
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          )
        }
      />
    </div>
  );
};

export default Information;
