import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const sync = async (id) => {
  return await axios.put(`${API_URL}/net-suite-purchase-sync/${id}`, { headers: authHeader() });
};

export default {
  sync,
};
