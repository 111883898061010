import jsPDF from "jspdf";
import { receptionPlaces } from "utils/Service";

export const form08Frente = (dataBuyer, dataVehicle, form, place) => {
  const doc08 = new jsPDF({
    orientation: "p",
    unit: "cm",
    format: [21, 29.7], //medidas 08 en pixeles = 20.3 cm x 30.5cm
  });
  doc08.setFont("helvetica");
  doc08.setFontSize(10);
  const placeName = receptionPlaces().find((r) => r.value === place).text;
  // Lugar de toma
  switch (place) {
    // DOT
    case 1:
      // Dominio
      doc08.text(10.3, 5.2, dataVehicle.plate_number);
      // Datos del comprador
      if (dataBuyer) {
        doc08.text(5.0, 7.0, dataBuyer.percentage.number);
        doc08.text(5.9, 7.0, dataBuyer.percentage.fraction);
        doc08.text(6.1, 7.5, dataBuyer.fullName);
        doc08.text(6.1, 8.1, dataBuyer.lastName);
        doc08.text(6.5, 8.6, dataBuyer.email);
        doc08.text(6.5, 9.1, dataBuyer.phone);
        doc08.text(6.1, 9.6, dataBuyer.address.street);
        doc08.text(5.3, 10.3, dataBuyer.address.number);
        doc08.text(7.3, 10.3, dataBuyer.address.floor);
        doc08.text(8.8, 10.3, dataBuyer.address.apartment);
        doc08.text(10.3, 10.3, dataBuyer.address.zipCode);
        doc08.text(6.1, 10.9, dataBuyer.address.city);
        doc08.text(10.1, 11.5, dataBuyer.address.province);
        doc08.text(6.1, 12.1, dataBuyer.address.street);
        doc08.text(5.1, 12.7, dataBuyer.address.number);
        doc08.text(7.1, 12.7, dataBuyer.address.floor);
        doc08.text(8.6, 12.7, dataBuyer.address.apartment);
        doc08.text(10.1, 12.7, dataBuyer.address.zipCode);
        doc08.text(6.1, 13.5, dataBuyer.address.city);
        doc08.text(10.1, 14.1, dataBuyer.address.province);

        doc08.text(8.3, 14.7, dataBuyer.profession);
        doc08.text(7.3, 17.2, dataBuyer.cuit);
        doc08.text(8.1, 20.4, dataBuyer.personeria);
        doc08.text(5.2, 21.4, dataBuyer.inscriptionData.number);
        doc08.text(9.6, 21.4, dataBuyer.inscriptionData.date.day);
        doc08.text(10.6, 21.4, dataBuyer.inscriptionData.date.month);
        doc08.text(11.3, 21.4, dataBuyer.inscriptionData.date.year);
      }
      // Datos del vechículo
      doc08.text(9.3, 24.5, dataVehicle.plate_number);
      doc08.text(6.4, 24.9, dataVehicle.car.brand);
      doc08.text(5.9, 25.4, dataVehicle.car.type);
      doc08.text(6.3, 25.8, dataVehicle.car.model);
      doc08.text(15.1, 24.0, dataVehicle.engine.engineBrand);
      doc08.text(15.1, 24.5, dataVehicle.engine.engineNumber);
      doc08.text(15.2, 24.9, dataVehicle.engine.chasisBrand);
      doc08.text(15.1, 25.4, dataVehicle.engine.chasisNumber);
      doc08.text(14.1, 25.8, dataVehicle.car.use);
      break;
    // TOM
    case 2:
      // Dominio
      doc08.text(10.0, 5.1, dataVehicle.plate_number);
      // Datos del comprador
      if (dataBuyer) {
        doc08.text(4.7, 6.9, dataBuyer.percentage.number);
        doc08.text(5.6, 6.9, dataBuyer.percentage.fraction);
        doc08.text(6.1, 7.4, dataBuyer.fullName);
        doc08.text(6.1, 8.0, dataBuyer.lastName);
        doc08.text(6.5, 8.5, dataBuyer.email);
        doc08.text(6.5, 9.0, dataBuyer.phone);
        doc08.text(6.1, 9.5, dataBuyer.address.street);
        doc08.text(5.1, 10.1, dataBuyer.address.number);
        doc08.text(7.1, 10.1, dataBuyer.address.floor);
        doc08.text(8.6, 10.1, dataBuyer.address.apartment);
        doc08.text(10.1, 10.1, dataBuyer.address.zipCode);
        doc08.text(6.1, 10.7, dataBuyer.address.city);
        doc08.text(10.1, 11.3, dataBuyer.address.province);
        doc08.text(6.1, 11.9, dataBuyer.address.street);
        doc08.text(5.1, 12.5, dataBuyer.address.number);
        doc08.text(7.1, 12.5, dataBuyer.address.floor);
        doc08.text(8.6, 12.5, dataBuyer.address.apartment);
        doc08.text(10.1, 12.5, dataBuyer.address.zipCode);
        doc08.text(6.1, 13.3, dataBuyer.address.city);
        doc08.text(10.1, 13.9, dataBuyer.address.province);

        doc08.text(8.1, 14.5, dataBuyer.profession);
        doc08.text(7.1, 17.1, dataBuyer.cuit);
        doc08.text(8.1, 20.2, dataBuyer.personeria);
        doc08.text(4.4, 21.2, dataBuyer.inscriptionData.number);
        doc08.text(8.7, 21.2, dataBuyer.inscriptionData.date.day);
        doc08.text(9.7, 21.2, dataBuyer.inscriptionData.date.month);
        doc08.text(10.4, 21.2, dataBuyer.inscriptionData.date.year);
      }
      // Datos del vechículo
      doc08.text(9.1, 24.3, dataVehicle.plate_number);
      doc08.text(6.1, 24.7, dataVehicle.car.brand);
      doc08.text(5.6, 25.2, dataVehicle.car.type);
      doc08.text(6.1, 25.6, dataVehicle.car.model);
      doc08.text(15.1, 23.8, dataVehicle.engine.engineBrand);
      doc08.text(15.1, 24.3, dataVehicle.engine.engineNumber);
      doc08.text(15.1, 24.7, dataVehicle.engine.chasisBrand);
      doc08.text(15.1, 25.2, dataVehicle.engine.chasisNumber);
      doc08.text(14.1, 25.6, dataVehicle.car.use);
      break;
    // Abasto
    case 3:
      // Dominio
      doc08.text(10.0, 5.2, dataVehicle.plate_number);
      // Datos del comprador
      if (dataBuyer) {
        doc08.text(4.7, 7.0, dataBuyer.percentage.number);
        doc08.text(5.6, 7.0, dataBuyer.percentage.fraction);
        doc08.text(6.1, 7.5, dataBuyer.fullName);
        doc08.text(6.1, 8.1, dataBuyer.lastName);
        doc08.text(6.5, 8.6, dataBuyer.email);
        doc08.text(6.5, 9.1, dataBuyer.phone);
        doc08.text(6.1, 9.7, dataBuyer.address.street);
        doc08.text(5.1, 10.3, dataBuyer.address.number);
        doc08.text(7.1, 10.3, dataBuyer.address.floor);
        doc08.text(8.6, 10.3, dataBuyer.address.apartment);
        doc08.text(10.1, 10.3, dataBuyer.address.zipCode);
        doc08.text(6.1, 10.9, dataBuyer.address.city);
        doc08.text(10.1, 11.5, dataBuyer.address.province);
        doc08.text(6.1, 12.1, dataBuyer.address.street);
        doc08.text(5.1, 12.7, dataBuyer.address.number);
        doc08.text(7.1, 12.7, dataBuyer.address.floor);
        doc08.text(8.6, 12.7, dataBuyer.address.apartment);
        doc08.text(10.1, 12.7, dataBuyer.address.zipCode);
        doc08.text(6.1, 13.5, dataBuyer.address.city);
        doc08.text(10.1, 14.1, dataBuyer.address.province);

        doc08.text(8.1, 14.9, dataBuyer.profession);
        doc08.text(7.1, 17.5, dataBuyer.cuit);
        doc08.text(8.1, 20.7, dataBuyer.personeria);
        doc08.text(5.1, 21.7, dataBuyer.inscriptionData.number);
        doc08.text(9.5, 21.7, dataBuyer.inscriptionData.date.day);
        doc08.text(10.5, 21.7, dataBuyer.inscriptionData.date.month);
        doc08.text(11.2, 21.7, dataBuyer.inscriptionData.date.year);
      }
      // Datos del vechículo
      doc08.text(9.1, 24.9, dataVehicle.plate_number);
      doc08.text(6.1, 25.3, dataVehicle.car.brand);
      doc08.text(5.6, 25.8, dataVehicle.car.type);
      doc08.text(6.1, 26.2, dataVehicle.car.model);
      doc08.text(15.1, 24.4, dataVehicle.engine.engineBrand);
      doc08.text(15.1, 24.9, dataVehicle.engine.engineNumber);
      doc08.text(15.1, 25.3, dataVehicle.engine.chasisBrand);
      doc08.text(15.1, 25.8, dataVehicle.engine.chasisNumber);
      doc08.text(14.1, 26.2, dataVehicle.car.use);
      break;
    // Argentina
    case 10:
      // Dominio
      doc08.text(10.2, 5.0, dataVehicle.plate_number);
      // Datos del comprador
      if (dataBuyer) {
        doc08.text(4.8, 6.8, dataBuyer.percentage.number);
        doc08.text(5.7, 6.8, dataBuyer.percentage.fraction);
        doc08.text(6.2, 7.3, dataBuyer.fullName);
        doc08.text(6.2, 7.9, dataBuyer.lastName);
        doc08.text(6.6, 8.4, dataBuyer.email);
        doc08.text(6.6, 8.9, dataBuyer.phone);
        doc08.text(6.2, 9.3, dataBuyer.address.street);
        doc08.text(5.2, 10.0, dataBuyer.address.number);
        doc08.text(7.2, 10.0, dataBuyer.address.floor);
        doc08.text(8.7, 10.0, dataBuyer.address.apartment);
        doc08.text(10.2, 10.1, dataBuyer.address.zipCode);
        doc08.text(6.2, 10.7, dataBuyer.address.city);
        doc08.text(10.2, 11.3, dataBuyer.address.province);
        doc08.text(6.2, 11.8, dataBuyer.address.street);
        doc08.text(5.2, 12.4, dataBuyer.address.number);
        doc08.text(7.2, 12.4, dataBuyer.address.floor);
        doc08.text(8.7, 12.4, dataBuyer.address.apartment);
        doc08.text(10.2, 12.4, dataBuyer.address.zipCode);
        doc08.text(6.2, 13.1, dataBuyer.address.city);
        doc08.text(10.2, 13.8, dataBuyer.address.province);

        doc08.text(8.2, 14.5, dataBuyer.profession);
        doc08.text(7.1, 17.1, dataBuyer.cuit);
        doc08.text(8.1, 20.2, dataBuyer.personeria);
        doc08.text(5.1, 21.2, dataBuyer.inscriptionData.number);
        doc08.text(9.5, 21.2, dataBuyer.inscriptionData.date.day);
        doc08.text(10.5, 21.2, dataBuyer.inscriptionData.date.month);
        doc08.text(11.2, 21.2, dataBuyer.inscriptionData.date.year);
      }
      // Datos del vechículo
      doc08.text(9.2, 24.3, dataVehicle.plate_number);
      doc08.text(6.2, 24.7, dataVehicle.car.brand);
      doc08.text(5.7, 25.2, dataVehicle.car.type);
      doc08.text(6.2, 25.6, dataVehicle.car.model);
      doc08.text(15.1, 23.7, dataVehicle.engine.engineBrand);
      doc08.text(15.1, 24.2, dataVehicle.engine.engineNumber);
      doc08.text(15.1, 24.6, dataVehicle.engine.chasisBrand);
      doc08.text(15.1, 25.1, dataVehicle.engine.chasisNumber);
      doc08.text(14.1, 25.6, dataVehicle.car.use);
      break;
    // Torre Bellini
    case 11:
      // Dominio
      doc08.text(10.2, 5.0, dataVehicle.plate_number);
      // Datos del comprador
      if (dataBuyer) {
        doc08.text(4.8, 6.8, dataBuyer.percentage.number);
        doc08.text(5.7, 6.8, dataBuyer.percentage.fraction);
        doc08.text(6.2, 7.3, dataBuyer.fullName);
        doc08.text(6.2, 7.9, dataBuyer.lastName);
        doc08.text(6.6, 8.4, dataBuyer.email);
        doc08.text(6.6, 8.9, dataBuyer.phone);
        doc08.text(6.2, 9.3, dataBuyer.address.street);
        doc08.text(5.2, 10.0, dataBuyer.address.number);
        doc08.text(7.2, 10.0, dataBuyer.address.floor);
        doc08.text(8.7, 10.0, dataBuyer.address.apartment);
        doc08.text(10.2, 10.1, dataBuyer.address.zipCode);
        doc08.text(6.2, 10.7, dataBuyer.address.city);
        doc08.text(10.2, 11.3, dataBuyer.address.province);
        doc08.text(6.2, 11.8, dataBuyer.address.street);
        doc08.text(5.2, 12.4, dataBuyer.address.number);
        doc08.text(7.2, 12.4, dataBuyer.address.floor);
        doc08.text(8.7, 12.4, dataBuyer.address.apartment);
        doc08.text(10.2, 12.4, dataBuyer.address.zipCode);
        doc08.text(6.2, 13.1, dataBuyer.address.city);
        doc08.text(10.2, 13.8, dataBuyer.address.province);

        doc08.text(8.2, 14.5, dataBuyer.profession);
        doc08.text(7.1, 17.1, dataBuyer.cuit);
        doc08.text(8.1, 20.2, dataBuyer.personeria);
        doc08.text(5.1, 21.2, dataBuyer.inscriptionData.number);
        doc08.text(9.5, 21.2, dataBuyer.inscriptionData.date.day);
        doc08.text(10.5, 21.2, dataBuyer.inscriptionData.date.month);
        doc08.text(11.2, 21.2, dataBuyer.inscriptionData.date.year);
      }
      // Datos del vechículo
      doc08.text(9.2, 24.3, dataVehicle.plate_number);
      doc08.text(6.2, 24.7, dataVehicle.car.brand);
      doc08.text(5.7, 25.2, dataVehicle.car.type);
      doc08.text(6.2, 25.6, dataVehicle.car.model);
      doc08.text(15.1, 23.7, dataVehicle.engine.engineBrand);
      doc08.text(15.1, 24.2, dataVehicle.engine.engineNumber);
      doc08.text(15.1, 24.6, dataVehicle.engine.chasisBrand);
      doc08.text(15.1, 25.1, dataVehicle.engine.chasisNumber);
      doc08.text(14.1, 25.6, dataVehicle.car.use);
      break;

    // Imprime como el DOT si es una que no este en el listado
    default:
      // Dominio
      doc08.text(10.0, 5.2, dataVehicle.plate_number);
      // Datos del comprador
      if (dataBuyer) {
        doc08.text(4.7, 7.0, dataBuyer.percentage.number);
        doc08.text(5.6, 7.0, dataBuyer.percentage.fraction);
        doc08.text(6.1, 7.5, dataBuyer.fullName);
        doc08.text(6.1, 8.1, dataBuyer.lastName);
        doc08.text(6.5, 8.6, dataBuyer.email);
        doc08.text(6.5, 9.1, dataBuyer.phone);
        doc08.text(6.1, 9.7, dataBuyer.address.street);
        doc08.text(5.1, 10.3, dataBuyer.address.number);
        doc08.text(7.1, 10.3, dataBuyer.address.floor);
        doc08.text(8.6, 10.3, dataBuyer.address.apartment);
        doc08.text(10.1, 10.3, dataBuyer.address.zipCode);
        doc08.text(6.1, 10.9, dataBuyer.address.city);
        doc08.text(10.1, 11.5, dataBuyer.address.province);
        doc08.text(6.1, 12.1, dataBuyer.address.street);
        doc08.text(5.1, 12.7, dataBuyer.address.number);
        doc08.text(7.1, 12.7, dataBuyer.address.floor);
        doc08.text(8.6, 12.7, dataBuyer.address.apartment);
        doc08.text(10.1, 12.7, dataBuyer.address.zipCode);
        doc08.text(6.1, 13.5, dataBuyer.address.city);
        doc08.text(10.1, 14.1, dataBuyer.address.province);

        doc08.text(8.1, 14.9, dataBuyer.profession);
        doc08.text(7.1, 17.5, dataBuyer.cuit);
        doc08.text(8.1, 20.7, dataBuyer.personeria);
        doc08.text(5.1, 21.7, dataBuyer.inscriptionData.number);
        doc08.text(9.5, 21.7, dataBuyer.inscriptionData.date.day);
        doc08.text(10.5, 21.7, dataBuyer.inscriptionData.date.month);
        doc08.text(11.2, 21.7, dataBuyer.inscriptionData.date.year);
      }
      // Datos del vechículo
      doc08.text(9.1, 24.9, dataVehicle.plate_number);
      doc08.text(6.1, 25.3, dataVehicle.car.brand);
      doc08.text(5.6, 25.8, dataVehicle.car.type);
      doc08.text(6.1, 26.2, dataVehicle.car.model);
      doc08.text(15.1, 24.4, dataVehicle.engine.engineBrand);
      doc08.text(15.1, 24.9, dataVehicle.engine.engineNumber);
      doc08.text(15.1, 25.3, dataVehicle.engine.chasisBrand);
      doc08.text(15.1, 25.8, dataVehicle.engine.chasisNumber);
      doc08.text(14.1, 26.2, dataVehicle.car.use);
      break;
  }
  doc08.save(`${placeName}-formulario-${form}-${dataVehicle.plate_number}.pdf`);
};

export const form08Dorso = (dataVehicle, form, place) => {
  const doc08 = new jsPDF({
    orientation: "p",
    unit: "cm",
    format: [21, 29.7], //medida 08 en pixeles = 20.3 cm x 30.5cm
  });
  doc08.setFont("helvetica");
  doc08.setFontSize(10);
  const placeName = receptionPlaces().find((r) => r.value === place).text;
  const dataSeller1 = dataVehicle.owners[0];
  const dataSeller2 = dataVehicle.owners[1];

  const percentage1 = `${dataSeller1?.holder_percentage}`.split(".");
  const percentage2 = `${dataSeller2?.holder_percentage}`.split(".");

  let marital_status_1_x = null;
  let marital_status_2_x = null;

  let document_1_x = null;
  let document_2_x = null;

  // Lugar de toma
  switch (place) {
    // DOT
    case 1:
      // Datos del vendedor 1
      doc08.text(1.4, 2.2, percentage1[0]);
      doc08.text(2.3, 2.2, percentage1[1] || "0");
      doc08.text(1.3, 2.9, dataSeller1.holder_name);
      switch (dataSeller1.marital_status) {
        case "SOLTERO":
          marital_status_1_x = 3.3;
          break;
        case "CASADO":
          marital_status_1_x = 4.6;
          break;
        case "VIUDO":
          marital_status_1_x = 5.7;
          break;
        case "DIVORCIADO":
          marital_status_1_x = 6.8;
          break;
        default:
          marital_status_1_x = null;
          break;
      }
      doc08.text(marital_status_1_x, 3.7, "x");
      // document_type
      switch (dataSeller1.document_type) {
        case "D.N.I.":
          document_1_x = 1.7;
          break;
        case "D.N.I.Ex":
          document_1_x = 5.0;
          break;
        default:
          document_1_x = null;
          break;
      }
      doc08.text(document_1_x, 7.0, "x");
      doc08.text(1.7, 7.6, dataSeller1.document_number);
      doc08.text(5.2, 7.6, dataSeller1.nationality);

      // Si hay un 2do titular
      if (!!dataSeller2) {
        doc08.text(9.0, 2.3, percentage2[0]);
        doc08.text(9.9, 2.3, percentage2[1] || "0");
        doc08.text(8.9, 2.9, dataSeller2.holder_name);
        switch (dataSeller2.marital_status) {
          case "SOLTERO":
            marital_status_2_x = 10.9;
            break;
          case "CASADO":
            marital_status_2_x = 12.1;
            break;
          case "VIUDO":
            marital_status_2_x = 13.2;
            break;
          case "DIVORCIADO":
            marital_status_2_x = 14.3;
            break;
          default:
            marital_status_2_x = null;
            break;
        }
        doc08.text(marital_status_2_x, 3.7, "x");
        // document_type
        switch (dataSeller2.document_type) {
          case "D.N.I.":
            document_2_x = 9.3;
            break;
          case "D.N.I.Ex":
            document_2_x = 12.6;
            break;
          default:
            document_2_x = null;
            break;
        }
        doc08.text(document_2_x, 7.0, "x");
        doc08.text(9.3, 7.6, dataSeller2.document_number);
        doc08.text(12.8, 7.6, dataSeller2.nationality);
      }
      break;
    // TOM
    case 2:
      // Datos del vendedor 1
      doc08.text(1.2, 2.2, percentage1[0]);
      doc08.text(2.1, 2.2, percentage1[1] || "0");
      doc08.text(1.2, 2.9, dataSeller1.holder_name);
      switch (dataSeller1.marital_status) {
        case "SOLTERO":
          marital_status_1_x = 3.0;
          break;
        case "CASADO":
          marital_status_1_x = 4.3;
          break;
        case "VIUDO":
          marital_status_1_x = 5.4;
          break;
        case "DIVORCIADO":
          marital_status_1_x = 6.5;
          break;
        default:
          marital_status_1_x = null;
          break;
      }
      doc08.text(marital_status_1_x, 3.7, "x");
      // document_type
      switch (dataSeller1.document_type) {
        case "D.N.I.":
          document_1_x = 1.6;
          break;
        case "D.N.I.Ex":
          document_1_x = 4.9;
          break;
        default:
          document_1_x = null;
          break;
      }
      doc08.text(document_1_x, 6.8, "x");
      doc08.text(1.6, 7.4, dataSeller1.document_number);
      doc08.text(5.1, 7.4, dataSeller1.nationality);

      // Si hay un 2do titular
      if (!!dataSeller2) {
        doc08.text(8.8, 2.3, percentage2[0]);
        doc08.text(9.7, 2.3, percentage2[1] || "0");
        doc08.text(8.8, 2.9, dataSeller2.holder_name);
        switch (dataSeller2.marital_status) {
          case "SOLTERO":
            marital_status_2_x = 10.7;
            break;
          case "CASADO":
            marital_status_2_x = 11.9;
            break;
          case "VIUDO":
            marital_status_2_x = 13.0;
            break;
          case "DIVORCIADO":
            marital_status_2_x = 14.1;
            break;
          default:
            marital_status_2_x = null;
            break;
        }
        doc08.text(marital_status_2_x, 3.7, "x");
        // document_type
        switch (dataSeller2.document_type) {
          case "D.N.I.":
            document_2_x = 9.2;
            break;
          case "D.N.I.Ex":
            document_2_x = 12.5;
            break;
          default:
            document_2_x = null;
            break;
        }
        doc08.text(document_2_x, 6.8, "x");
        doc08.text(9.2, 7.4, dataSeller2.document_number);
        doc08.text(12.7, 7.4, dataSeller2.nationality);
      }
      break;
    // Abasto
    case 3:
      // Datos del vendedor 1
      doc08.text(1.2, 2.2, percentage1[0]);
      doc08.text(2.1, 2.2, percentage1[1] || "0");
      doc08.text(1.2, 2.9, dataSeller1.holder_name);
      switch (dataSeller1.marital_status) {
        case "SOLTERO":
          marital_status_1_x = 3.2;
          break;
        case "CASADO":
          marital_status_1_x = 4.5;
          break;
        case "VIUDO":
          marital_status_1_x = 5.6;
          break;
        case "DIVORCIADO":
          marital_status_1_x = 6.7;
          break;
        default:
          marital_status_1_x = null;
          break;
      }
      doc08.text(marital_status_1_x, 3.7, "x");
      // document_type
      switch (dataSeller1.document_type) {
        case "D.N.I.":
          document_1_x = 1.6;
          break;
        case "D.N.I.Ex":
          document_1_x = 4.9;
          break;
        default:
          document_1_x = null;
          break;
      }
      doc08.text(document_1_x, 7.0, "x");
      doc08.text(1.6, 7.6, dataSeller1.document_number);
      doc08.text(5.1, 7.6, dataSeller1.nationality);

      // Si hay un 2do titular
      if (!!dataSeller2) {
        doc08.text(8.8, 2.3, percentage2[0]);
        doc08.text(9.7, 2.3, percentage2[1] || "0");
        doc08.text(8.8, 2.9, dataSeller2.holder_name);
        switch (dataSeller2.marital_status) {
          case "SOLTERO":
            marital_status_2_x = 10.8;
            break;
          case "CASADO":
            marital_status_2_x = 12.0;
            break;
          case "VIUDO":
            marital_status_2_x = 13.1;
            break;
          case "DIVORCIADO":
            marital_status_2_x = 14.2;
            break;
          default:
            marital_status_2_x = null;
            break;
        }
        doc08.text(marital_status_2_x, 3.7, "x");
        // document_type
        switch (dataSeller2.document_type) {
          case "D.N.I.":
            document_2_x = 9.2;
            break;
          case "D.N.I.Ex":
            document_2_x = 12.5;
            break;
          default:
            document_2_x = null;
            break;
        }
        doc08.text(document_2_x, 7.0, "x");
        doc08.text(9.2, 7.6, dataSeller2.document_number);
        doc08.text(12.7, 7.6, dataSeller2.nationality);
      }
      break;
    // Argentina
    case 10:
      // Datos del vendedor 1
      doc08.text(1.4, 2.0, percentage1[0]);
      doc08.text(2.3, 2.0, percentage1[1] || "0");
      doc08.text(1.4, 2.7, dataSeller1.holder_name);
      switch (dataSeller1.marital_status) {
        case "SOLTERO":
          marital_status_1_x = 3.4;
          break;
        case "CASADO":
          marital_status_1_x = 4.7;
          break;
        case "VIUDO":
          marital_status_1_x = 5.8;
          break;
        case "DIVORCIADO":
          marital_status_1_x = 6.9;
          break;
        default:
          marital_status_1_x = null;
          break;
      }
      doc08.text(marital_status_1_x, 3.5, "x");
      // document_type
      switch (dataSeller1.document_type) {
        case "D.N.I.":
          document_1_x = 1.8;
          break;
        case "D.N.I.Ex":
          document_1_x = 5.1;
          break;
        default:
          document_1_x = null;
          break;
      }
      doc08.text(document_1_x, 6.8, "x");
      doc08.text(1.8, 7.4, dataSeller1.document_number);
      doc08.text(5.3, 7.4, dataSeller1.nationality);

      // Si hay un 2do titular
      if (!!dataSeller2) {
        doc08.text(8.9, 2.2, percentage2[0]);
        doc08.text(9.8, 2.2, percentage2[1] || "0");
        doc08.text(8.9, 2.7, dataSeller2.holder_name);
        switch (dataSeller2.marital_status) {
          case "SOLTERO":
            marital_status_2_x = 11.0;
            break;
          case "CASADO":
            marital_status_2_x = 12.2;
            break;
          case "VIUDO":
            marital_status_2_x = 13.3;
            break;
          case "DIVORCIADO":
            marital_status_2_x = 14.4;
            break;
          default:
            marital_status_2_x = null;
            break;
        }
        doc08.text(marital_status_2_x, 3.6, "x");
        // document_type
        switch (dataSeller2.document_type) {
          case "D.N.I.":
            document_2_x = 9.3;
            break;
          case "D.N.I.Ex":
            document_2_x = 12.6;
            break;
          default:
            document_2_x = null;
            break;
        }
        doc08.text(document_2_x, 6.7, "x");
        doc08.text(9.4, 7.3, dataSeller2.document_number);
        doc08.text(12.9, 7.3, dataSeller2.nationality);
      }
      break;
    // Torre Bellini
    case 11:
      // Datos del vendedor 1
      doc08.text(1.4, 2.0, percentage1[0]);
      doc08.text(2.3, 2.0, percentage1[1] || "0");
      doc08.text(1.4, 2.7, dataSeller1.holder_name);
      switch (dataSeller1.marital_status) {
        case "SOLTERO":
          marital_status_1_x = 3.4;
          break;
        case "CASADO":
          marital_status_1_x = 4.7;
          break;
        case "VIUDO":
          marital_status_1_x = 5.8;
          break;
        case "DIVORCIADO":
          marital_status_1_x = 6.9;
          break;
        default:
          marital_status_1_x = null;
          break;
      }
      doc08.text(marital_status_1_x, 3.5, "x");
      // document_type
      switch (dataSeller1.document_type) {
        case "D.N.I.":
          document_1_x = 1.8;
          break;
        case "D.N.I.Ex":
          document_1_x = 5.1;
          break;
        default:
          document_1_x = null;
          break;
      }
      doc08.text(document_1_x, 6.8, "x");
      doc08.text(1.8, 7.4, dataSeller1.document_number);
      doc08.text(5.3, 7.4, dataSeller1.nationality);

      // Si hay un 2do titular
      if (!!dataSeller2) {
        doc08.text(8.9, 2.2, percentage2[0]);
        doc08.text(9.8, 2.2, percentage2[1] || "0");
        doc08.text(8.9, 2.7, dataSeller2.holder_name);
        switch (dataSeller2.marital_status) {
          case "SOLTERO":
            marital_status_2_x = 11.0;
            break;
          case "CASADO":
            marital_status_2_x = 12.2;
            break;
          case "VIUDO":
            marital_status_2_x = 13.3;
            break;
          case "DIVORCIADO":
            marital_status_2_x = 14.4;
            break;
          default:
            marital_status_2_x = null;
            break;
        }
        doc08.text(marital_status_2_x, 3.6, "x");
        // document_type
        switch (dataSeller2.document_type) {
          case "D.N.I.":
            document_2_x = 9.3;
            break;
          case "D.N.I.Ex":
            document_2_x = 12.6;
            break;
          default:
            document_2_x = null;
            break;
        }
        doc08.text(document_2_x, 6.7, "x");
        doc08.text(9.4, 7.3, dataSeller2.document_number);
        doc08.text(12.9, 7.3, dataSeller2.nationality);
      }
      break;

    // Imprime como el DOT si es una que no este en el listado
    default:
      // Datos del vendedor 1
      doc08.text(1.2, 2.2, percentage1[0]);
      doc08.text(2.1, 2.2, percentage1[1] || "0");
      doc08.text(1.2, 2.9, dataSeller1.holder_name);
      switch (dataSeller1.marital_status) {
        case "SOLTERO":
          marital_status_1_x = 3.2;
          break;
        case "CASADO":
          marital_status_1_x = 4.5;
          break;
        case "VIUDO":
          marital_status_1_x = 5.6;
          break;
        case "DIVORCIADO":
          marital_status_1_x = 6.7;
          break;
        default:
          marital_status_1_x = null;
          break;
      }
      doc08.text(marital_status_1_x, 3.7, "x");
      // document_type
      switch (dataSeller1.document_type) {
        case "D.N.I.":
          document_1_x = 1.6;
          break;
        case "D.N.I.Ex":
          document_1_x = 4.9;
          break;
        default:
          document_1_x = null;
          break;
      }
      doc08.text(document_1_x, 7.0, "x");
      doc08.text(1.6, 7.6, dataSeller1.document_number);
      doc08.text(5.1, 7.6, dataSeller1.nationality);

      // Si hay un 2do titular
      if (!!dataSeller2) {
        doc08.text(8.8, 2.3, percentage2[0]);
        doc08.text(9.7, 2.3, percentage2[1] || "0");
        doc08.text(8.8, 2.9, dataSeller2.holder_name);
        switch (dataSeller2.marital_status) {
          case "SOLTERO":
            marital_status_2_x = 10.8;
            break;
          case "CASADO":
            marital_status_2_x = 12.0;
            break;
          case "VIUDO":
            marital_status_2_x = 13.1;
            break;
          case "DIVORCIADO":
            marital_status_2_x = 14.2;
            break;
          default:
            marital_status_2_x = null;
            break;
        }
        doc08.text(marital_status_2_x, 3.7, "x");
        // document_type
        switch (dataSeller2.document_type) {
          case "D.N.I.":
            document_2_x = 9.2;
            break;
          case "D.N.I.Ex":
            document_2_x = 12.5;
            break;
          default:
            document_2_x = null;
            break;
        }
        doc08.text(document_2_x, 7.0, "x");
        doc08.text(9.2, 7.6, dataSeller2.document_number);
        doc08.text(12.7, 7.6, dataSeller2.nationality);
      }
      break;
  }

  doc08.save(`${placeName}-formulario-${form}-${dataVehicle.plate_number}.pdf`);
};

export default {};
