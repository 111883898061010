import { Multiselect } from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ProgressBar from "../../../../ProgressBar/ProgressBar";
import Itembox from "./itemBox";
import FormService from "../../../../../services/form.service";

const AddForms = ({
  forms,
  fields,
  allowRemove,
  onSimpleFieldChange,
  setForms,
}) => {
  const [completed, setCompleted] = useState(0);
  const [selectedForm, setSelectedForm] = useState([]);

  const onSelectForm = (_, selectedItem) => {
    setSelectedForm([selectedItem]);
  };

  const onRemoveForm = () => {
    setSelectedForm([]);
  };

  const setProgressBar = (folio_forms) => {
    const perc =
      folio_forms.length > 0
        ? (folio_forms.filter((f) => f.has_folio_in_progress === true).length *
            100) /
          folio_forms.length
        : 0;
    setCompleted(parseInt(perc, 10));
  };

  useEffect(() => {
    setProgressBar(fields.folio_forms);
  }, [fields]);

  const onComplexFieldChange = (primaryKey, value) => {
    const folio_in_progress_forms_tmp = [...fields.folio_forms];
    const idx = folio_in_progress_forms_tmp.findIndex(
      (f) => f.form_code === primaryKey
    );
    folio_in_progress_forms_tmp[idx].has_folio_in_progress = value; // check that!
    onSimpleFieldChange("folio_forms", [...folio_in_progress_forms_tmp]);

    setCompleted(
      (folio_in_progress_forms_tmp.filter(
        (f) => f.has_folio_in_progress === true
      ).length *
        100) /
        folio_in_progress_forms_tmp.length
    );
  };

  const onAddForm = () => {
    const params = {
      opportunity_id: fields.opportunity_id,
      form_code: selectedForm[0].id,
      vehicle_id: null,
    };
    FormService.addToFolio(params).then((response) => {
      if (response.success) {
        // Se agrega a los formularios listados
        const newArray = [...fields.folio_forms];
        newArray.push({
          form_code: selectedForm[0].id,
          has_folio_in_progress: false,
          name: selectedForm[0].name,
        });
        onSimpleFieldChange("folio_forms", [...newArray]);
        // Se elimina la opción del selector
        const idx = forms.findIndex((f) => f.id === selectedForm[0].id);
        forms.splice(idx, 1);
        // Limpiamos la opcion seleccionada
        setSelectedForm([]);
      }
    });
  };

  const onDeleteForm = (item, e) => {
    e.preventDefault();
    const {
      title,
      checkbox: { name: code },
    } = item;
    const params = {
      opportunity_id: fields.opportunity_id,
      form_code: code,
      vehicle_id: null,
    };
    FormService.removeFromFolio(params).then((response) => {
      if (response.success) {
        // Se elimina el formulario del listado
        const newArray = fields.folio_forms.filter((f) => f.form_code !== code);
        onSimpleFieldChange("folio_forms", [...newArray]);
        // Se agrega la opción del selector
        setForms([...forms, { id: code, name: title }]);
      }
    });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col">
            <label className="capitalize">
              <strong>Formularios Disponibles</strong>
            </label>
            <Multiselect
              options={forms}
              selectedValues={selectedForm}
              onSelect={onSelectForm}
              onRemove={onRemoveForm}
              displayValue="name"
              placeholder="Formulario"
              closeIcon="cancel"
              selectionLimit="1"
              showCheckbox={false}
              singleSelect={false}
            />
          </div>
          <div className="col">
            <label>&nbsp;</label>
            <div>
              <Button
                className="btn-fill pull-left btn btn-info btn-sm capitalize"
                onClick={() => onAddForm()}
                disabled={selectedForm.length === 0}
              >
                Agregar
              </Button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="container">
        {fields.folio_forms.map((f, index) => (
          <Itembox
            item={{
              title: f.name,
              checkbox: {
                name: f.form_code,
                checked: f.has_folio_in_progress,
              },
            }}
            key={index}
            onComplexFieldChange={onComplexFieldChange}
            allowRemove={allowRemove}
            onDeleteForm={onDeleteForm}
          />
        ))}
        <div className="row">
          <div className="col">
            <ProgressBar key={"idx1"} bgcolor={"green"} completed={completed} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForms;
