import Dropdown from "components/Table/Dropdown";
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import List from "../../components/Table/List";
import AuthService from "../../services/auth.service";
import ProfileService from "../../services/profile.service";
import UserService from "../../services/user.service";
import { useToast } from "../../hooks";
import { updateLocalStorageCache } from "utils/Service";

const headSort = [
  { text: "Id", field: "id", model: null },
  { text: "Nombre", field: "first_name", model: null },
  { text: "Apellido", field: "last_name", model: null },
  { text: "Usuario", field: "user_name", model: null },
  { text: "Email", field: "email", model: null },
  { text: "Rol", field: "profile_id", model: null },
  { text: "", field: null, model: null },
];

const UsersList = (props) => {
  const [body, setBody] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    model: null,
    field: "id",
    type: "ASC",
  });
  const countItems = 10;
  const addToast = useToast();

  const [profiles, setProfiles] = useState([]);

  const [profileToFilter, setProfileToFilter] = useState('');

  useEffect(() => {
    const params = {
      currentPage,
      countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
    };
    const permissions = AuthService.getCurrentPermissions("USUARIOS");
    setCurrentPermissions(permissions);
    ProfileService.getAll(params).then((response) => {
      if (response.data.count > 0) {
        setProfiles(
          response.data.response.map((obj) => ({
            value: obj.id,
            text: obj.description,
          }))
        );
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = {
      currentPage,
      countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      profile: profileToFilter,
    };
    UserService.getAll(params).then((response) => {
      if (response.data.success) {
        setBody(response.data.response);
        setTotalItems(response.data.totalItems);
        const result = Math.ceil(response.data.totalItems / countItems);
        setTotalPage(result);
      }
    });
  }, [currentPage, currentSort, profileToFilter]);

  const handleDelete = (id, e) => {
    e.preventDefault();
    const del = window.confirm("¿Eliminar usuario?");
    if (del) {
      UserService.del(id).then(() => {
        let list = body.filter((data) => data.id !== parseInt(id));
        setBody(list);
        addToast({
          variant: "success",
          header: "Usuarios",
          body: "Se eliminó el usuario correctamente",
        });
        updateLocalStorageCache();
      });
    }
  };

  const handleToPage = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const handleSort = (model, field, type) => {
    setCurrentSort({ model, field, type });
    setCurrentPage(0);
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasUpdate ? (
          <a
            href={`/admin/users/${props.id}/edit`}
            className="btn-simple btn btn-xs btn-info"
          >
            <i className="fas fa-edit"></i>
          </a>
        ) : null}

        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <button
            onClick={(e) => handleDelete(props.id, e)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </button>
        ) : null}
      </>
    );
  };

  const handlerRoles = (e) => {
    setProfileToFilter(e.target.value !== "default" ? e.target.value : '');
    setCurrentPage(0);
  };

  return (
    <div className="content">
      <Container fluid>
        <Button href="/admin/users/create" className="btn-fill btn btn-primary">
          Nuevo Usuario
        </Button>
        <br />
        <div className="content-filters row mt-2">
          <div className="col-sm-4">
            <Dropdown
              className="form-control"
              name="profiles"
              placeholder="Seleccionar perfil"
              onSelect={handlerRoles}
              selected={profileToFilter}
              options={profiles}
            />
          </div>
        </div>
        <br />
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
      </Container>
    </div>
  );
};

export default UsersList;
