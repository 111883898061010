import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import Filters from "../../components/Filters/index.js";
import List from "../../components/Table/List.js";
import RejectSell from "components/Modal/Sells/rejectSell.js";
import Edit from "../../components/Modal/Sells/general/edit";
import ExcelButton from "../../components/ExcelButton/index.js";
import { useFilters, useGrid, useStatusModal } from "../../hooks/index";
import AuthService from "../../services/auth.service";
import GeneralService from "../../services/sells/sellsGeneral.service";
import sellsGeneralService from "../../services/sells/sellsGeneral.service";
import {
  badgeGenericBoolean,
  diffDateBadge,
  genericBadge,
  sinceDaysBadge,
  twoBadges,
} from "../../utils/Badges.js";
import { categories, sellEmployees } from "../../utils/Service.js";
import {
  constants,
  meses,
  sells_status,
  yesNoOptions,
  sells_status_select,
} from "../../variables/constants";
import { FINANCED_STATUS_NAME, providerOptions } from "../../variables/sells/index";
import Modal from "../../layouts/ModalDisplay";
import styles from "../General/styles.module.scss";
import { headGeneral } from "./constants/constants_columns";

const years = [];
for (let index = moment().year(); index >= 2021; index--) {
  years.push({ value: index, text: index });
}

const months = [];
for (let index = 1; index <= 12; index++) {
  months.push({ value: index, text: meses[index] });
}

const filterFields = {
  plate_number: {
    type: "text",
    name: "plate_number",
    placeholder: "Dominio",
    value: "",
    size: 3,
  },
  year: {
    type: "select",
    name: "year",
    options: years,
    placeholder: "Año",
    value: "",
    size: 2,
  },
  month: {
    type: "select",
    name: "month",
    options: months,
    placeholder: "Mes",
    value: "",
    size: 2,
  },
  suspended: {
    type: "select",
    name: "suspended",
    options: yesNoOptions,
    placeholder: "Suspendido",
    value: "",
    size: 2,
  },
  person_in_charge: {
    type: "select",
    name: "person_in_charge",
    options: sellEmployees(),
    placeholder: "Persona Responsable",
    value: "",
    size: 3,
  },
  status_ns: {
    type: "select",
    name: "status_ns",
    options: categories(),
    placeholder: "Estado NS",
    value: undefined,
    size: 3,
  },
  provider: {
    type: "select",
    name: "provider",
    options: providerOptions,
    placeholder: "Proveedor",
    value: "",
    size: 3,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: sells_status_select,
    placeholder: "Estado",
    value: [],
    size: 6,
  },
};

const finalStatus = [sells_status.COMPLETED.value, sells_status.REJECTED.value];

const GeneralList = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [dataModal, setDataModal] = useState([]);

  const {
    body,
    countItems,
    currentPage,
    currentSort,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    refresh,
    refreshGrid,
    totalItems,
    totalPage,
    cleanPage,
  } = useGrid({
    field: "opportunity_id",
    type: "ASC",
  });

  const {
    handleCloseEditModal,
    handleCloseRejectModal,
    handleOpenEditModal,
    handleShowRejectModal,
    showEditModal,
    showRejectModal,
  } = useStatusModal();

  const selectFilters = () => {
    const local_profile = JSON.parse(
      localStorage.getItem("user")
    ).data.user.profile.name.toLowerCase();
    if (local_profile === constants.onboardProfile) {
      filterFields.suspended.hide = true;
      filterFields.status_ns.hide = true;
      filterFields.plate_number.size = 2;
      filterFields.year.size = 1;
      filterFields.month.size = 1;
      filterFields.person_in_charge.size = 2;
      filterFields.provider.size = 2;
      filterFields.status.size = 4;
      // filterFields.status.hide = true;
      // filterFields.status.value = sells_status_select.filter(f => f.onboard);
      filterFields.status.options = sells_status_select.filter(f => f.onboard);    }
      return filterFields;
    };

  const { filters, onFilterChange } = useFilters(selectFilters(), cleanPage);
  const [emptyFilters, setEmptyFilters] = useState(true);

  const next = {
    link: "/admin/rejected/list",
    text: "Rechazados",
  };
  // PAGINATE:FIN

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("GENERAL VENTAS");
    setCurrentPermissions(permissions);
  }, []);

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
      filters,
    };
    const emptyFilters = Object.keys(params.filters).every(
      (key) => 
        params.filters[key].value === "" || 
        params.filters[key].value === undefined || 
        params.filters[key].value.length === 0
    );
    setEmptyFilters(emptyFilters);
    if(emptyFilters) {
      handleTotalItems(0);
      handleTotalPage(0);
      handleBody(sortColumns([]));
      return
    }

    GeneralService.getAll(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      handleBody(sortColumns(response.data.response));
    });
    //eslint-disable-next-line
  }, [currentPage, currentSort, filters, refresh]);

  const sortColumns = (body) =>
    body.map((e) => ({
      opportunity_id: e.opportunity_id,
      plate_number: twoBadges(
        e.plate_number,
        e.financed ? FINANCED_STATUS_NAME.FINANCED : null
      ),
      stock_id: e.stock_id,
      status_ns: e.status_ns,
      financing_provider: e.financing_provider,
      year: e.year,
      month: e.month,
      week: e.week,
      delivery_date: e.delivery_date,
      signing_form_08_date: e.signing_form_08_date,
      receive_form_08_date: e.receive_form_08_date,
      financed_date: e.financed_date,
      folio_in_progress_date: e.folio_in_progress_date,
      presentation_date: e.presentation_date,
      presentation_real_date: e.presentation_real_date,
      presentation_retired_real_date: e.presentation_retired_real_date,
      reception_cat_date: e.reception_cat_date,
      take_licence_date: e.take_licence_date,
      status: genericBadge(sells_status, e.status),
      estimated_delivery_date: e.estimated_delivery_date,
      estimated_end_trial: e.estimated_end_trial,
      person_in_charge_id: e.person_in_charge_id,
      suspended_date: e.suspended_date,
      observed_date: e.observed_date,
      active_days: diffDateBadge(e.active_days),
      aging: diffDateBadge(e.aging),
      diff_days: diffDateBadge(e.diff_days),
      // since_days: sinceDaysBadge(e.since_days),
      bacs_id: badgeGenericBoolean(e.bacs_id),
      bacs_desafectado: badgeGenericBoolean(e.bacs_desafectado),
      imagenes: e.imagenes,
    }));

  const handleShowModal = (id, e) => {
    e.preventDefault();
    sellsGeneralService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect ? (
          <Button
            onClick={(e) => handleShowModal(props.opportunity_id, e)}
            className="btn-simple btn btn-xs btn-secondary"
          >
            <i className="fas fa-file-alt" aria-hidden="true"></i>
          </Button>
        ) : null}
        {currentPermissions &&
        currentPermissions.permissions.hasDelete &&
        !finalStatus.includes(props.status_id) ? (
          <Button
            onClick={(e) => handleShowRejectModal(props.opportunity_id, e)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </Button>
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className={styles.export}>
        <ExcelButton type="ventasGeneral" filters={filters} />
      </div>
      <Filters filterFields={filterFields} onFilterChange={onFilterChange} />
      <List
        thead={headGeneral}
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
        emptyFilters={emptyFilters}
      />
      <Modal
        content={
          <Edit
            data={dataModal}
            handleClose={handleCloseEditModal}
            refresh={refreshGrid}
          />
        }
        showModal={showEditModal}
        handleClose={handleCloseEditModal}
      />
      {showRejectModal.show && (
        <Modal
          content={
            <RejectSell
              handleClose={handleCloseRejectModal}
              refresh={refreshGrid}
              id={showRejectModal.id}
            />
          }
          showModal={showRejectModal.show}
          handleShow={showRejectModal}
          handleClose={handleCloseRejectModal}
          headerTitle={<div>Motivos del rechazo</div>}
        />
      )}
    </div>
  );
};

export default GeneralList;
