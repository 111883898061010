import jsPDF from "jspdf";
import { receptionPlaces } from "utils/Service";

export const form02 = (dataVehicle, form, place) => {
  const placeName = receptionPlaces().find((r) => r.value === place).text;
  const doc02 = new jsPDF({
    orientation: "p",
    unit: "cm",
    format: [21, 29.7], //medida 08 en pixeles = 20.3 cm x 30.5cm
  });
  doc02.setFont("helvetica");
  doc02.setFontSize(8);
  doc02.text(16.5, 24.9, dataVehicle.plate_number);
  doc02.text(14, 25.3, dataVehicle.car.brand);
  doc02.text(13.6, 25.7, dataVehicle.car.type);
  doc02.text(14.1, 26.1, dataVehicle.car.model);
  doc02.text(14.9, 26.5, dataVehicle.engine.engineBrand);
  doc02.text(15.2, 26.9, dataVehicle.engine.engineNumber);
  doc02.text(15, 27.3, dataVehicle.engine.chasisBrand);
  doc02.text(15.1, 27.7, dataVehicle.engine.chasisNumber);
  doc02.save(`${placeName}-formulario-${form}-${dataVehicle.plate_number}.pdf`);
};

export default {};
