// Constantes de la aplicación
export const constants = {
  accept: "Aceptar",
  activate: "activar",
  active: "activo",
  add: "agregar",
  address: "dirección",
  adminProfile: "administrador",
  sellsSuspendProfile: "ventas suspension",
  sellsBackofficeProfile: "back office ventas",
  sellsPostSellsProfile: "post venta",
  agentPresenting: "gestor que lo presenta",
  agentInternalProfile: "gestor interno",
  agentExternalProfile: "gestor externo",
  employeeProfile: "empleado",
  driverProfile: "drivers",
  operatorProfile: "operador vtv",
  apartment: "departamento",
  apply: "Aplica",
  attachments: "adjuntos",
  back: "volver",
  cancel: "cancelar",
  canceled: "Anulado",
  certificateOfTitle: "Informe de Dominio",
  city: "ciudad",
  close: "cerrar",
  closed: "cerrado",
  comment: "comentario",
  comments: "comentarios",
  completed: "Completado",
  continue: "continuar",
  coordinatorProfile: "coordinador",
  createVehicleManually: "crear vehículo manualmente",
  delivered: "Entregado",
  deliveryPlace: "Lugar entrega cédula",
  deliveryPlaceDate: "Fecha entrega cédula",
  description: "descripción",
  domain: "dominio",
  enterpriseSignature: "firma empresa",
  estimatedDate: "Fecha Estimada Gestoría",
  expired: "Expirado",
  extraordinaryDate: "Fecha Extraordinaria",
  files: "archivos",
  finalize: "finalizar",
  finalPaymentDate: "fecha último pago",
  floor: "piso",
  folioInProgress: "Apertura Folio",
  form03: "Formulario 03",
  form08: "Formulario 08",
  general: "General",
  greenLicence: "Cédula Verde",
  history: "historial",
  holidays: "Feriados",
  inhibited: "inhibido",
  inquiry: "Consulta",
  inspector: "tomador",
  issuerEntity: "registro",
  judicialMeasures: "embargado",
  latitude: "latitud",
  legalize: "legalizado",
  limitAssigment08: 200,
  longitude: "longitud",
  lossRequest: "solicitud de extravío",
  managementType: "tipo de gestión",
  name: "nombre",
  noComments: "-",
  none: "ninguno",
  noResults: "sin resultados",
  notAplly: "No Aplica",
  notaryOffice: "lugar de Firma",
  notaryOffices: "escribanías",
  notaryProfile: "escribano",
  notReceived: "No Recibido",
  number: "número",
  observation: "observación",
  observed: "Observado",
  observedAgent: "gestor que observó",
  observedDate: "Fecha de observación",
  observedDelivered: "Reentregado",
  observedExpired: "Observado (Expirado)",
  observedPresentedDate: "Fecha de re-presentado",
  observedReceived: "Observado (Recibido)",
  open: "abierto",
  onboardProfile: "onboarder hub",
  pending: "Pendiente",
  pendingReception: "Pendiente de Recepción",
  personInCharge: "persona responsable",
  phone: "teléfono",
  plateNumber: "matrícula",
  pledge: "prendado",
  Presentation: "Presentación Registro",
  presentedAgent: "gestor que presentó",
  price: "precio",
  prontoform: "prontoform",
  province: "provincia",
  purchaseCanceledStatus: "Requiere Baja",
  purchaseForm: "Título del auto",
  purchaseForms: "formularios de compra",
  purchasePrice: "monto de compra",
  realObservedPresentedDate: "Fecha Real re-presentación",
  realPresentationDate: "Fecha Real Presentación",
  realRetiredDate: "Fecha Real Retiro",
  receiptTake: "Recibo Toma",
  received: "Recibido",
  receptionCatDate: "CAT Recibido",
  receptionPlace: "Lugar de Toma",
  remaining08Start: 60,
  remaining08LegalizeDiscarted: 30,
  removed: "Cancelado",
  required: "requerido",
  reschedule: "re agendado",
  sdp: "SDP 3.0",
  send: "enviar",
  sent: "enviado",
  signatureCount: "cantidad de firmas",
  signed: "Firmado",
  status: "estado",
  suspend: "suspender",
  swornStatement: "DDJJ P. física / ext. título / UIF",
  takeLicenceDate: "Cédula Recibida",
  taker: "tomador",
  theoretical: "teórico",
  transactionType: "tipo de operación",
  turnRequestDate: "Fecha Turno Presentación",
  turnReRequestDate: "Fecha Turno Re-presentación",
  turnTakeDate: "Fecha Turno Retiro",
  update: "actualizar",
  validate: "validar",
  vehicle: "vehículo",
  vehicleSaveManually: "Vehículo ingresado manualmente",
  void: "descartado",
  vpa: "VPA",
  waiting: "En Espera",
  watch: "Observar",
  zeroTwo: "02",
  zipCode: "código postal",
  zone: "zona",
  url_certificate_of_title:
    "https://www2.jus.gov.ar/dnrpa-site/api/consultaEstado/informe",
  dropdownDefault: "default",
  refreshInterval: 120000, // 2 minutos
  schedule_allow_comment_reason_id: "20",
  logisticlAdmin: "administrador logistico",
  readOnlyProfile: "Solo Lectura",
};

export const status08Conditions = {
  NONE: {
    text: constants.none,
    value: "NONE",
  },
  OPEN: {
    text: constants.open,
    value: "OPEN",
  },
  CLOSED: {
    text: constants.closed,
    value: "CLOSED",
  },
};

export const purchaseStatus = {
  CREATED: {
    value: "CREATED",
    text: "Creado",
    color: "badgeCreated",
  },
  REJECTED: {
    value: "REJECTED",
    text: "Rechazado",
    color: "badgeRejected",
  },
  PENDING_VALIDATION: {
    value: "PENDING_VALIDATION",
    text: "Pendiente",
    color: "badgePendingValidation",
    group: "take",
  },
  VALIDATION_EXCEPTION: {
    value: "VALIDATION_EXCEPTION",
    text: "Excepción",
    color: "badgeValidationException",
    group: "take",
  },
  ACCEPT: {
    value: "ACCEPT",
    text: "Aceptado",
    color: "badgeAccept",
  },
  SIGNING_FORM_08: {
    value: "SIGNING_FORM_08",
    text: "Firma 08",
    color: "badgeSigningForm08",
    group: "take",
  },
  FOLIO_IN_PROGRESS: {
    value: "FOLIO_IN_PROGRESS",
    text: "Folio en Progreso",
    color: "badgeFolioInProgress",
    group: "folio",
  },
  FOLIO_IN_PROGRESS_STAND_BY: {
    value: "FOLIO_IN_PROGRESS_STAND_BY",
    text: "Folio en Progreso - En Espera",
    color: "badgeFolioInProgressStandBy",
    group: "folio",
  },
  DELIVERED: {
    value: "DELIVERED",
    text: "Entregado",
    color: "badgeDelivered",
  },
  DELIVERY_REJECTED: {
    value: "DELIVERY_REJECTED",
    text: "Entrega Rechazada",
    color: "badgeDeliveryRejected",
  },
  PRESENTATION_WAITING: {
    value: "PRESENTATION_WAITING",
    text: "Presentación - En Espera",
    color: "badgePresentationWaiting",
    group: "presentation",
    next: "PRESENTATION_DELIVERED",
    order: 1,
  },
  PRESENTATION_DELIVERED: {
    value: "PRESENTATION_DELIVERED",
    text: "Presentación - Entregado",
    color: "badgePresentationDelivered",
    group: "presentation",
    next: "PRESENTATION_PRESENTED",
    order: 2,
  },
  PRESENTATION_PRESENTED: {
    value: "PRESENTATION_PRESENTED",
    text: "Presentación - Presentado",
    color: "badgePresentationPresented",
    group: "presentation",
    next: "PRESENTATION_CAT_RECEIVED",
    order: 3,
  },
  PRESENTATION_CAT_RECEIVED: {
    value: "PRESENTATION_CAT_RECEIVED",
    text: "Presentación - CAT Recibido",
    color: "badgePresentationReceptionCat",
    group: "presentation",
    next: "PRESENTATION_RETIRED",
    order: 4,
  },
  PRESENTATION_RETIRED: {
    value: "PRESENTATION_RETIRED",
    text: "Presentación - Retirado",
    color: "badgePresentationRetired",
    group: "presentation",
    order: 5,
  },
  PRESENTATION_OBSERVED: {
    value: "PRESENTATION_OBSERVED",
    text: "Presentación - Observado",
    color: "badgePresentationObserved",
    group: "presentation",
    next: "PRESENTATION_OBSERVED_DELIVERED",
    order: 6,
  },
  PRESENTATION_OBSERVED_RETIRED: {
    value: "PRESENTATION_OBSERVED_RETIRED",
    text: "Presentación - Observado (Retirado)",
    color: "badgePresentationObserved",
    group: "presentation",
    next: "PRESENTATION_OBSERVED_DELIVERED",
    order: 7,
  },
  PRESENTATION_OBSERVED_DELIVERED: {
    value: "PRESENTATION_OBSERVED_DELIVERED",
    text: "Presentación - Re-entregado",
    color: "badgePresentationObservedDelivered",
    group: "presentation",
    next: "PRESENTATION_OBSERVED_PRESENTED",
    order: 8,
  },
  PRESENTATION_OBSERVED_PRESENTED: {
    value: "PRESENTATION_OBSERVED_PRESENTED",
    text: "Presentación - Re-presentado",
    color: "badgePresentationObservedPresented",
    group: "presentation",
    next: "PRESENTATION_CAT_RECEIVED",
    order: 9,
  },
  COMPLETED: {
    value: "COMPLETED",
    text: "Completado",
    color: "badgeCompleted",
  },
  DISMISSED_KAVAK: {
    value: "DISMISSED_KAVAK",
    text: "Desestimado KAVAK",
    color: "badgeRejected",
  },
  PURCHASE_SUSPENDED: {
    value: "PURCHASE_SUSPENDED",
    text: "Suspendido",
    color: "badgeDeliveryRejected",
    group: "suspended",
  },
  PRESENTATION_SUSPENDED: {
    value: "PRESENTATION_SUSPENDED",
    text: "Presentación Suspendido",
    group: "presentation",
    color: "badgeDeliveryRejected",
  },
  FOLIO_SUSPENDED: {
    value: "FOLIO_SUSPENDED",
    text: "Folio Suspendido",
    color: "badgeDeliveryRejected",
    group: "folio",
  },
  PURCHASE_OBSERVED: {
    value: "PURCHASE_OBSERVED",
    text: "Observado",
    color: "badgePresentationObserved",
    group: "take",
  },
  FOLIO_IN_PROGRESS_OBSERVED: {
    value: "FOLIO_IN_PROGRESS_OBSERVED",
    text: "Folio en Progreso - Observado",
    color: "badgePresentationObserved",
    group: "folio",
  },
};

export const purchase_status_select = [
  {
    text: purchaseStatus.PURCHASE_SUSPENDED.text,
    value: purchaseStatus.PURCHASE_SUSPENDED.value,
    suspended: true,
  },
  {
    text: purchaseStatus.PRESENTATION_SUSPENDED.text,
    value: purchaseStatus.PRESENTATION_SUSPENDED.value,
  },
  {
    text: purchaseStatus.FOLIO_SUSPENDED.text,
    value: purchaseStatus.FOLIO_SUSPENDED.value,
    suspended: true,
  },
  {
    text: purchaseStatus.ACCEPT.text,
    value: purchaseStatus.ACCEPT.value,
  },
  {
    text: purchaseStatus.CREATED.text,
    value: purchaseStatus.CREATED.value,
  },
  {
    text: purchaseStatus.REJECTED.text,
    value: purchaseStatus.REJECTED.value,
  },
  {
    text: purchaseStatus.PENDING_VALIDATION.text,
    value: purchaseStatus.PENDING_VALIDATION.value,
  },
  {
    text: purchaseStatus.VALIDATION_EXCEPTION.text,
    value: purchaseStatus.VALIDATION_EXCEPTION.value,
  },
  {
    text: purchaseStatus.SIGNING_FORM_08.text,
    value: purchaseStatus.SIGNING_FORM_08.value,
  },
  {
    text: purchaseStatus.FOLIO_IN_PROGRESS.text,
    value: purchaseStatus.FOLIO_IN_PROGRESS.value,
    group: "folio",
  },
  {
    text: purchaseStatus.FOLIO_IN_PROGRESS_STAND_BY.text,
    value: purchaseStatus.FOLIO_IN_PROGRESS_STAND_BY.value,
    group: "folio",
  },
  {
    text: purchaseStatus.DELIVERED.text,
    value: purchaseStatus.DELIVERED.value,
  },
  {
    text: purchaseStatus.DELIVERY_REJECTED.text,
    value: purchaseStatus.DELIVERY_REJECTED.value,
  },
  {
    text: purchaseStatus.COMPLETED.text,
    value: purchaseStatus.COMPLETED.value,
  },
  {
    text: purchaseStatus.PRESENTATION_WAITING.text,
    value: purchaseStatus.PRESENTATION_WAITING.value,
    group: "PRESENTATION",
  },
  {
    text: purchaseStatus.PRESENTATION_DELIVERED.text,
    value: purchaseStatus.PRESENTATION_DELIVERED.value,
    group: "PRESENTATION",
  },
  {
    text: purchaseStatus.PRESENTATION_PRESENTED.text,
    value: purchaseStatus.PRESENTATION_PRESENTED.value,
    group: "PRESENTATION",
  },
  {
    text: purchaseStatus.PRESENTATION_OBSERVED.text,
    value: purchaseStatus.PRESENTATION_OBSERVED.value,
    group: "PRESENTATION",
  },
  {
    text: purchaseStatus.PRESENTATION_OBSERVED_DELIVERED.text,
    value: purchaseStatus.PRESENTATION_OBSERVED_DELIVERED.value,
    group: "PRESENTATION",
  },
  {
    text: purchaseStatus.PRESENTATION_OBSERVED_PRESENTED.text,
    value: purchaseStatus.PRESENTATION_OBSERVED_PRESENTED.value,
    group: "PRESENTATION",
  },
  {
    text: purchaseStatus.PRESENTATION_CAT_RECEIVED.text,
    value: purchaseStatus.PRESENTATION_CAT_RECEIVED.value,
    group: "PRESENTATION",
  },
  {
    text: purchaseStatus.PRESENTATION_RETIRED.text,
    value: purchaseStatus.PRESENTATION_RETIRED.value,
    group: "PRESENTATION",
  },
  {
    text: purchaseStatus.PRESENTATION_SUSPENDED.text,
    value: purchaseStatus.PRESENTATION_SUSPENDED.value,
    group: "PRESENTATION",
    suspended: true,
  },
  {
    text: purchaseStatus.PRESENTATION_OBSERVED_RETIRED.text,
    value: purchaseStatus.PRESENTATION_OBSERVED_RETIRED.value,
    group: "PRESENTATION",
  },
  {
    text: purchaseStatus.PURCHASE_OBSERVED.text,
    value: purchaseStatus.PURCHASE_OBSERVED.value,
  },
  {
    text: purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.text,
    value: purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.value,
    group: "folio",
  },
];

export const status08 = {
  WAITING: {
    color: "secondary",
    text: constants.waiting,
    value: "WAITING",
    next: "SIGNED",
    profile: constants.notaryProfile,
  },
  SIGNED: {
    color: "warning",
    text: constants.signed,
    value: "SIGNED",
    next: "DELIVERED",
    profile: constants.notaryProfile,
  },
  EXPIRED: {
    color: "danger",
    text: constants.expired,
    value: "EXPIRED",
    next: "DELIVERED",
    profile: constants.notaryProfile,
  },
  DELIVERED: {
    color: "primary",
    text: constants.delivered,
    value: "DELIVERED",
    next: "RECEIVED",
    profile: constants.adminProfile,
  },
  RECEIVED: {
    color: "success",
    text: constants.received,
    value: "RECEIVED",
    next: "OBSERVED",
    profile: constants.adminProfile,
  },
  OBSERVED: {
    color: "warning",
    text: constants.observed,
    value: "OBSERVED",
    next: "OBSERVED_DELIVERED",
    profile: constants.notaryProfile,
  },
  OBSERVED_EXPIRED: {
    color: "danger",
    text: constants.observedExpired,
    value: "OBSERVED_EXPIRED",
    next: "OBSERVED_DELIVERED",
    profile: constants.notaryProfile,
  },
  OBSERVED_DELIVERED: {
    color: "primary",
    text: constants.observedDelivered,
    value: "OBSERVED_DELIVERED",
    next: "OBSERVED_RECEIVED",
    profile: constants.adminProfile,
  },
  OBSERVED_RECEIVED: {
    color: "success",
    text: constants.observedReceived,
    value: "OBSERVED_RECEIVED",
    next: "OBSERVED",
    profile: constants.adminProfile,
  },
  CANCELED: {
    color: "dark",
    text: constants.canceled,
    value: "CANCELED",
    profile: constants.adminProfile,
  },
  COMPLETED: {
    color: "info",
    text: constants.completed,
    value: "COMPLETED",
    profile: constants.adminProfile,
  },
  REMOVED: {
    color: "dark",
    text: constants.removed,
    value: "REMOVED",
    profile: constants.adminProfile,
    cancelReasonId: 8,
  },
  ARCHIVED: {
    color: "dark",
    text: "Archivado",
    value: "ARCHIVED",
    profile: constants.adminProfile,
    cancelReasonId: 13,
  },
};

export const sells_status = {
  PENDING_VALIDATION: {
    color: "badgeWarning",
    text: "Pendiente",
    value: 1,
    profile: constants.adminProfile,
    cancelReasonId: 1,
  },
  VALIDATION_EXCEPTION: {
    color: "badgePrimary",
    text: "Observado",
    value: 2,
    profile: constants.adminProfile,
    cancelReasonId: 2,
  },
  SIGNING_FORM_08: {
    color: "badgeSigningForm08",
    text: "Firma 08",
    value: 3,
    profile: constants.adminProfile,
    cancelReasonId: 3,
  },
  FOLIO_IN_PROGRESS: {
    color: "badgeFolioInProgress",
    text: "Folio en Progreso",
    value: 4,
    profile: constants.adminProfile,
    cancelReasonId: 4,
  },
  FOLIO_IN_PROGRESS_STAND_BY: {
    color: "badgeFolioInProgressStandBy",
    text: "Folio en Progreso - En Espera",
    value: 5,
    profile: constants.adminProfile,
    cancelReasonId: 5,
  },
  PRESENTATION_WAITING: {
    color: "badgePresentationWaiting",
    text: "Presentación - En Espera",
    value: 6,
    profile: constants.adminProfile,
    next: "PRESENTATION_DELIVERED",
    cancelReasonId: 6,
  },
  PRESENTATION_DELIVERED: {
    color: "badgePresentationDelivered",
    text: "Presentación - Entregado",
    value: 7,
    profile: constants.adminProfile,
    next: "PRESENTATION_PRESENTED",
    cancelReasonId: 7,
  },
  PRESENTATION_PRESENTED: {
    color: "badgePresentationPresented",
    text: "Presentación - Presentado",
    value: 8,
    profile: constants.adminProfile,
    next: "PRESENTATION_CAT_RECEIVED",
    cancelReasonId: 8,
  },
  PRESENTATION_OBSERVED: {
    color: "badgePresentationObserved",
    text: "Presentación - Observado",
    value: 9,
    profile: constants.adminProfile,
    next: "PRESENTATION_OBSERVED_DELIVERED",
    cancelReasonId: 9,
  },
  PRESENTATION_OBSERVED_DELIVERED: {
    color: "badgePresentationObservedDelivered",
    text: "Presentación - Re-entregado",
    value: 10,
    profile: constants.adminProfile,
    next: "PRESENTATION_OBSERVED_PRESENTED",
    cancelReasonId: 10,
  },
  PRESENTATION_OBSERVED_PRESENTED: {
    color: "badgePresentationObservedPresented",
    text: "Presentación - Re-presentado",
    value: 11,
    profile: constants.adminProfile,
    next: "PRESENTATION_CAT_RECEIVED",
    cancelReasonId: 11,
  },
  PRESENTATION_CAT_RECEIVED: {
    color: "badgePresentationReceptionCat",
    text: "Presentación - CAT Recibido",
    value: 12,
    profile: constants.adminProfile,
    next: "PRESENTATION_RETIRED",
    cancelReasonId: 12,
  },
  PRESENTATION_RETIRED: {
    color: "badgePresentationRetired",
    text: "Presentación - Retirado",
    value: 13,
    profile: constants.adminProfile,
    cancelReasonId: 13,
  },
  REJECTED: {
    color: "badgeRejected",
    text: "Rechazado",
    value: 14,
    profile: constants.adminProfile,
    cancelReasonId: 14,
  },
  COMPLETED: {
    color: "badgeCompleted",
    text: "Completado",
    value: 15,
    profile: constants.adminProfile,
    cancelReasonId: 15,
  },
  ERP_VENTAS_SUSPENDED: {
    color: "badgeRejected",
    text: "Suspendido",
    value: 16,
    profile: constants.adminProfile,
    cancelReasonId: 16,
  },
  ERP_VENTAS_OBSERVED: {
    color: "badgeWarning",
    text: "Observado",
    value: 17,
    profile: constants.adminProfile,
    cancelReasonId: 17,
  },
  FOLIO_IN_PROGRESS_SUSPENDED: {
    color: "badgeRejected",
    text: "Folio Suspendido",
    value: 18,
    profile: constants.adminProfile,
    cancelReasonId: 18,
  },
  FOLIO_IN_PROGRESS_OBSERVED: {
    color: "badgeDark",
    text: "Folio en Progreso - Observado",
    value: 19,
    profile: constants.adminProfile,
    cancelReasonId: 19,
  },
  PRESENTATION_SUSPENDED: {
    color: "badgeRejected",
    text: "Presentación - Suspendido",
    value: 20,
    profile: constants.adminProfile,
    cancelReasonId: 20,
  },
  PENDING_DOCUMENTATION_WITHDRAW: {
    color: "badgePendingDocWithdraw",
    text: "Pendiente Documentación - Retiro",
    value: 21,
    profile: constants.adminProfile,
    cancelReasonId: 21,
  },
  ARCHIVE: {
    color: "badgeRejected",
    text: "Archivado",
    value: 22,
    profile: constants.adminProfile,
    cancelReasonId: 22,
  },
  PENDING_DOCUMENTATION_DELIVERED: {
    color: "badgePendingDocDelivered",
    text: "Pendiente Documentación - Enviado",
    value: 23,
    profile: constants.adminProfile,
    next: "PENDING_DOCUMENTATION_RECEIVED",
    cancelReasonId: 23,
  },
  PRESENTATION_OBSERVED_RETIRED: {
    color: "badgePresentationObserved",
    text: "Presentación - Observado (Retirado)",
    value: 24,
    profile: constants.adminProfile,
    next: "PRESENTATION_OBSERVED_DELIVERED",
    cancelReasonId: 24,
  },
  PENDING_DOCUMENTATION_RECEIVED: {
    color: "badgePendingDocReceived",
    text: "Documentación Recibida por el Cliente",
    value: 25,
    profile: constants.adminProfile,
    next: "PRESENTATION_OBSERVED_DELIVERED",
    cancelReasonId: 25,
  },
};

export const delivery_types = [
  {
    text: "STD",
    value: "STD",
    trial: false,
  },
  {
    text: "NDD",
    value: "NDD",
    trial: true,
  },
  {
    text: "ONLINE",
    value: "ONLINE",
    trial: true,
  },
];

export const yesOrNotFinancedOptions = [
  { text: "Si", value: true },
  { text: "No", value: false },
];

export const form_type = {
  COMPRA: {
    text: "Compra",
    value: 1,
  },
  CONSIGNACION_COMPRA: {
    text: "Consignación Compra",
    value: 3,
  },
  RE_FIRMA_COMPRA: {
    text: "Re-Firma Compra",
    value: 6,
  },
  VENTA: {
    text: "Venta",
    value: 2,
    hideReception: true,
  },
  CONSIGNACION_VENTA: {
    text: "Consignación Venta",
    value: 8,
    hideReception: true,
  },
  RE_FIRMA_VENTA: {
    text: "Re-Firma Venta",
    value: 7,
    hideReception: true,
  },
  BACS: {
    text: "BACS",
    value: 4,
  },
  OTROS: {
    text: "Otros",
    value: 5,
  },
};

export const formType = Object.entries(form_type).map((l) => ({
  text: l[1].text,
  value: l[1].value,
}));

export const form08 = {
  buyerInfo: {
    percentage: {
      number: "100",
      fraction: "00",
    },
    fullName: "Check Movilidad S.A.",
    lastName: "Comerciante Habitualista",
    email: "gestoria.ar@kavak.com",
    phone: "1135941997",
    address: {
      street: "Vedia",
      number: "3600",
      floor: "3ss",
      apartment: "",
      zipCode: "1430",
      city: "C.A.B.A.",
      province: "BS. AS.",
    },
    profession: "COMER. HABIT.",
    cuit: "30-71594543-2",
    personeria: "IGJ",
    inscriptionData: {
      number: "3113 DEL Lº 88 DE SA",
      date: {
        day: "20",
        month: "02",
        year: "2018",
      },
    },
  },
  bacsInfo: {
    percentage: {
      number: "100",
      fraction: "00",
    },
    fullName: "BACS Banco de Credito",
    lastName: "y Securitizacion S.A",
    email: "fideicomisokavak@bacs.com.ar",
    phone: "1121656272",
    address: {
      street: "Tucuman",
      number: "1",
      floor: "19",
      apartment: "",
      zipCode: "1049",
      city: "C.A.B.A.",
      province: "BS. AS.",
    },
    profession: "FINANCIERA",
    cuit: "30-70722741-5",
    personeria: "IGJ",
    inscriptionData: {
      number: "6437 DEL LIBRO 11",
      date: {
        day: "10",
        month: "05",
        year: "2000",
      },
    },
  },
};

/**
 * Mensajes de la aplicación
 */
export const messages = {
  statusUpdated: "Se actualizó el estado",
  notAllformsReceived:
    "Hay formularios que no estan marcados como recibidos. ¿Desea continuar?",
};

export const meses = [
  "",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const googleMaps = {
  defaultLatitude: -34.5895597,
  defaultLongitude: -58.5218312,
  defaultZoom: 12,
  defaultMaxZoom: 19,
  defaultSelZoom: 17,
};

export const decision = {
  approval: "APPROVAL",
  rejection: "REJECTION",
};

export const dropdownStatus = [
  {
    value: purchaseStatus.PENDING_VALIDATION.value,
    text: purchaseStatus.PENDING_VALIDATION.text,
  },
  {
    value: purchaseStatus.VALIDATION_EXCEPTION.value,
    text: purchaseStatus.VALIDATION_EXCEPTION.text,
  },
  {
    value: purchaseStatus.SIGNING_FORM_08.value,
    text: purchaseStatus.SIGNING_FORM_08.text,
  },
];

export const legalizeStatus = {
  PENDING: {
    color: "info",
    text: constants.pending,
    value: "PENDING",
    text_color: "dark",
  },
  REQUIRED: {
    color: "danger",
    text: constants.required,
    value: "REQUIRED",
    text_color: "white",
  },
  VOID: {
    color: "warning",
    text: constants.void,
    value: "VOID",
    text_color: "dark",
  },
};

export const serviceTypes = {
  checkList: "CHECKLIST",
  folioInProgress: "FOLIOINPROGRESS",
  general: "GENERAL",
  presentation: "PRESENTATION",
  status08Form: "STATUS08",
  rejectedList: "REJECTEDLIST",
};

export const login = "/auth/login";

export const homePage = {
  [constants.adminProfile]: "/admin/dashboard",
  [constants.notaryProfile]: "/admin/dashboard-notary",
  [constants.agentInternalProfile]: "/admin/dashboard-agent",
  [constants.agentExternalProfile]: "/admin/dashboard-agent",
};

export const agentProfiles = [
  constants.agentInternalProfile,
  constants.agentExternalProfile,
];

export const fileSections = {
  form08: "form08",
  presentation: "presentation",
};

export const yesNoOptions = [
  { value: "si", text: "Si" },
  { value: "no", text: "No" },
];

export const canceledStatus = [
  { value: 0, text: "Sin datos" },
  { value: 1, text: "No requiere baja" },
  { value: 2, text: "Baja Pendiente" },
  { value: 3, text: "Baja realizada" },
];

export const sells_status_select = [
  {
    text: sells_status.PENDING_VALIDATION.text,
    value: sells_status.PENDING_VALIDATION.value,
    group: "SELLS",
  },
  {
    text: sells_status.VALIDATION_EXCEPTION.text,
    value: sells_status.VALIDATION_EXCEPTION.value,
    group: "SELLS",
  },
  {
    text: sells_status.SIGNING_FORM_08.text,
    value: sells_status.SIGNING_FORM_08.value,
    group: "SELLS",
  },
  {
    text: sells_status.FOLIO_IN_PROGRESS.text,
    value: sells_status.FOLIO_IN_PROGRESS.value,
    group: "FOLIO",
  },
  {
    text: sells_status.FOLIO_IN_PROGRESS_STAND_BY.text,
    value: sells_status.FOLIO_IN_PROGRESS_STAND_BY.value,
    group: "FOLIO",
  },
  {
    text: sells_status.PRESENTATION_WAITING.text,
    value: sells_status.PRESENTATION_WAITING.value,
    group: "PRESENTATION",
  },
  {
    text: sells_status.PRESENTATION_DELIVERED.text,
    value: sells_status.PRESENTATION_DELIVERED.value,
    group: "PRESENTATION",
  },
  {
    text: sells_status.PRESENTATION_PRESENTED.text,
    value: sells_status.PRESENTATION_PRESENTED.value,
    group: "PRESENTATION",
  },
  {
    text: sells_status.PRESENTATION_OBSERVED.text,
    value: sells_status.PRESENTATION_OBSERVED.value,
    group: "PRESENTATION",
  },
  {
    text: sells_status.PRESENTATION_OBSERVED_RETIRED.text,
    value: sells_status.PRESENTATION_OBSERVED_RETIRED.value,
    group: "PRESENTATION",
  },
  {
    text: sells_status.PRESENTATION_OBSERVED_DELIVERED.text,
    value: sells_status.PRESENTATION_OBSERVED_DELIVERED.value,
    group: "PRESENTATION",
  },
  {
    text: sells_status.PRESENTATION_OBSERVED_PRESENTED.text,
    value: sells_status.PRESENTATION_OBSERVED_PRESENTED.value,
    group: "PRESENTATION",
  },
  {
    text: sells_status.PRESENTATION_CAT_RECEIVED.text,
    value: sells_status.PRESENTATION_CAT_RECEIVED.value,
    group: "PRESENTATION",
  },
  {
    text: sells_status.PRESENTATION_RETIRED.text,
    value: sells_status.PRESENTATION_RETIRED.value,
    group: "PRESENTATION",
  },
  {
    text: sells_status.REJECTED.text,
    value: sells_status.REJECTED.value,
  },
  {
    text: sells_status.COMPLETED.text,
    value: sells_status.COMPLETED.value,
    onboard: true,
  },
  {
    text: sells_status.ERP_VENTAS_SUSPENDED.text,
    value: sells_status.ERP_VENTAS_SUSPENDED.value,
    group: "SELLS",
    suspended: true,
  },
  {
    text: sells_status.FOLIO_IN_PROGRESS_SUSPENDED.text,
    value: sells_status.FOLIO_IN_PROGRESS_SUSPENDED.value,
    group: "FOLIO",
    suspended: true,
  },
  {
    text: sells_status.FOLIO_IN_PROGRESS_OBSERVED.text,
    value: sells_status.FOLIO_IN_PROGRESS_OBSERVED.value,
    group: "FOLIO",
  },
  {
    text: sells_status.PRESENTATION_SUSPENDED.text,
    value: sells_status.PRESENTATION_SUSPENDED.value,
    group: "PRESENTATION",
    suspended: true,
  },
  {
    text: sells_status.PENDING_DOCUMENTATION_WITHDRAW.text,
    value: sells_status.PENDING_DOCUMENTATION_WITHDRAW.value,
    group: "PENDING_DOCUMENTATION",
    onboard: true,
  },
  {
    text: sells_status.PENDING_DOCUMENTATION_DELIVERED.text,
    value: sells_status.PENDING_DOCUMENTATION_DELIVERED.value,
    group: "PENDING_DOCUMENTATION",
    onboard: true,
  },
  {
    text: sells_status.PENDING_DOCUMENTATION_RECEIVED.text,
    value: sells_status.PENDING_DOCUMENTATION_RECEIVED.value,
    group: "PENDING_DOCUMENTATION",
    onboard: true,
  },
];

export const activate_button_suspended_sell = ["22", "23", "24"];
export const activate_button_suspended_purchase = ["29", "30", "31"];

export const withdrawAddressType = [
  {
    text: "Hub",
    value: "Hub",
  },
  {
    text: "Domicilio",
    value: "Domicilio",
  },
];

export const renditionType = [
  {
    text: "Compra",
    value: 1,
  },
  {
    text: "Venta",
    value: 2,
  },
  {
    text: "BACS",
    value: 3,
  },
];

// Adicionales
export const additionalType = [
  {
    text: "Compra",
    value: 1,
  },
  {
    text: "Venta",
    value: 2,
  },
];

export const additional_status = [
  {
    color: "badgeSecondary",
    text: "Indefinido",
    value: 0,
  },
  {
    color: "badgeWarning",
    text: "Iniciado",
    value: 1,
    profile: constants.notaryProfile,
    next: 2,
    decline: true,
  },
  {
    color: "badgePrimary",
    text: "Aceptado",
    value: 2,
    profile: constants.notaryProfile,
    next: 4,
  },
  {
    color: "badgeRejected",
    text: "Rechazado",
    value: 3,
    profile: constants.adminProfile,
    final: true,
  },
  {
    color: "badgeFolioInProgress",
    text: "Enviado",
    value: 4,
    profile: constants.adminProfile,
    next: 5,
    observation: true,
  },
  {
    color: "badgeFolioInProgressStandBy",
    text: "Recibido",
    value: 5,
    profile: constants.adminProfile,
  },
  {
    color: "badgePresentationWaiting",
    text: "Observado",
    value: 6,
    profile: constants.notaryProfile,
    next: 2,
    decline: true,
  },
  {
    color: "badgeRejected",
    text: "Anulado",
    value: 7,
    profile: constants.adminProfile,
    final: true,
  },
  {
    color: "badgeCompleted",
    text: "Facturado",
    value: 8,
    profile: constants.adminProfile,
    final: true,
  },
];

export const additionalStatus = additional_status
  .filter((f) => !!f.value)
  .map((s) => ({
    text: s.text,
    value: s.value,
  }));

export const appName = "hermes";

export const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
export const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
export const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
export const atLeastOneSpecialChar = /[#?!@$%^&*-.,_<>=+]/g; // any of the special characters within the square brackets
export const minCharsOrMore = /.{14,}/g; // 14 characters or more

export default {};
