import React from "react";
import Form from "react-validation/build/form";
import { BadgeField } from "../components";
import { useGetStatus } from "hooks";
import CustomAccordion from "../../../../components/Accordion";
import { diffDateFormula } from "../../../../utils/Badges";
import { sells_status } from "../../../../variables/constants";
import { financed_status, providerOptions } from "../../../../variables/sells";
import { SelectField, InputField } from "../../../../components/Form";

const CarInfo = ({ data, onSimpleFieldChange, fields, allowEdit }) => {
  const { status } = data.status_financed?.description
    ? useGetStatus(data.status_financed?.description, financed_status)
    : useGetStatus(data.status_sell?.description, sells_status);
  return (
    <CustomAccordion title={"Información del Prendado"} defaultOpen>
      <div className="row">
        <BadgeField
          label={"ID Oportunidad"}
          text={data.opportunity_id ?? "-"}
          badgeClass={`plate_number`}
          variant={"info"}
        />
        <BadgeField
          label={"Dominio"}
          text={data.domain ?? "-"}
          badgeClass={"plate_number"}
          variant={"warning"}
        />
        <BadgeField
          label={"Stock ID"}
          text={data.stock_id ?? "-"}
          badgeClass={`plate_number`}
          variant={"info"}
        />
        <BadgeField
          label={"Estado"}
          text={status.text}
          badgeClass={`badge ${status.color} capitalize`}
          style={{ width: "100%", padding: "13px" }}
        />
      </div>
      <div className="row">
        {!allowEdit ? (
          <>
            <BadgeField
              label={"Proveedor"}
              text={data.financing_provider ?? "-"}
              badgeClass={`capitalize plate_number`}
              variant={"secondary"}
            />
            <BadgeField
              label={"Importe Prestamo"}
              text={data.financing_amount ?? "-"}
              badgeClass={`plate_number`}
              variant={"dark"}
            />
          </>
        ) : (
          <>
            <div className="col">
              <SelectField
                label={"Proveedor"}
                options={providerOptions}
                onChange={(e) =>
                  onSimpleFieldChange(
                    "financing_provider",
                    !!e.target.value ? e.target.value : null
                  )
                }
                value={fields.financing_provider ?? data.financing_provider}
                name={"financing_provider"}
              />
            </div>
            <div className="col">
              <Form>
                <InputField
                  label={"Importe Prestamo"}
                  name={"financing_amount"}
                  onChange={(e) =>
                    onSimpleFieldChange("financing_amount", e.target.value)
                  }
                  value={fields.financing_amount ?? data.financing_amount}
                />
              </Form>
            </div>
          </>
        )}
        <BadgeField
          label={"Bacs"}
          text={data.bacs_id ? "SI" : "NO"}
          badgeClass={`capitalize plate_number`}
          variant={data.bacs_id ? "success" : "danger"}
        />
      </div>
    </CustomAccordion>
  );
};

export default CarInfo;
