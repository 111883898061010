import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { Card } from "../../components/Card/Card.jsx";
import AuthService from "../../services/auth.service";
import { appName, constants, homePage } from "../../variables/constants";
import { useToast } from "../../hooks";
import { updateLocalStorageCache } from "../../utils/Service";
import pkgJSON from "../../../package.json";
import logo from "assets/img/logo_kavak_color.svg";

const ERRORS = {
  USER_NOT_FOUND: "Credenciales Invalidas",
  USER_UNRELATED_NOTARY: "Usuario no asociado a una escribania",
};

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio!
      </div>
    );
  }
};

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const initialSetup = (res) => {
    const { user } = res.data; 
    updateLocalStorageCache().then(() => {
      window.location.assign(
        homePage[user.profile.name.toLowerCase()] ??
          homePage[constants.adminProfile]
      );
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(email, password).then(
        (res) => {
          initialSetup(res);
        },
        (error) => {
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Login",
            body: ERRORS[error.message] ?? error.message,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("version", pkgJSON?.version);
  }, [])

  return (
    <div className="content">
      <Container fluid>
        <Row className="login">
          <Col md={4}>
            <div className="content">
              <div className="logo">
                <img src={logo} alt="KAVAK" width="200px" />
                {appName}
              </div>
            </div>
            <Card
              content={
                <Form onSubmit={handleLogin} ref={form}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group mt-3">
                    <button
                      className="btn btn-primary btn-fill pull-right"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  <div className="clearfix" />
                </Form>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
