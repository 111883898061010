import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import { Container, Row, Col, Button } from "react-bootstrap";
import validator from "validator";
import { Card } from "components/Card/Card.jsx";
import NotFound from "components/Errors/NotFound.js";
import UserService from "../../services/user.service";
import ProfileService from "../../services/profile.service";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../hooks";
import { updateLocalStorageCache } from "utils/Service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!validator.isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        El correo electronico es incorrecto
      </div>
    );
  }
};

const UsersEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const user_id = params.id;
  const [profiles, setProfiles] = useState([]);
  const [profile_id, setProfileId] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [user_name, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [resourceExist, setResourceExist] = useState(true);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  useEffect(() => {
    async function getUserById() {
      UserService.getUserById(user_id)
        .then((response) => {
          if (response.data.success && response.data.data) {
            setProfileId(response.data.data.profile.id);
            setFirstName(response.data.data.first_name);
            setLastName(response.data.data.last_name);
            setUserName(response.data.data.user_name);
            setEmail(response.data.data.email);
          }
        })
        .catch((e) => {
          setResourceExist(false);
        });
    }
    getUserById();
  }, [user_id]);

  useEffect(() => {
    ProfileService.getAll({ sortField: "name", sortType: "ASC" }).then(
      (response) => {
        if (response.data.count > 0) {
          setProfiles(response.data.response);
        }
      }
    );
  }, []);

  const onChangeUserName = (e) => {
    const userName = e.target.value;
    setUserName(userName);
  };

  const onChangeLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };

  const onChangeFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const onChangeProfileId = (e) => {
    const utype = e.target.value;
    setProfileId(utype);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      UserService.edit(
        user_id,
        profile_id,
        first_name,
        last_name,
        user_name,
        email
      ).then(
        () => {
          addToast({
            variant: "success",
            header: "Usuarios",
            body: "Se actualizó la información correctamente",
          });
          updateLocalStorageCache().then(() => {
            navigate("/admin/users/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Usuarios",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Button href="/admin/users/list" className="btn-fill btn btn-info">
          Volver
        </Button>
        {resourceExist && (
          <Button
            href={`/admin/users/${user_id}/password`}
            className="btn-fill btn btn-secondary ms-2"
          >
            Cambiar contraseña
          </Button>
        )}
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                resourceExist ? (
                  <Form onSubmit={handSubmit} ref={form}>
                    <div className="form-group">
                      <label htmlFor="email">Tipo de usuario</label>
                      <Select
                        value={profile_id}
                        className="form-control"
                        name="user_type"
                        onChange={onChangeProfileId}
                        // validations={[required]}
                      >
                        <option value="">Seleccionar...</option>
                        {profiles.map((prop, key) => {
                          return (
                            <option
                              key={key}
                              value={prop.id}
                              default={prop.id !== profile_id ? true : false}
                            >
                              {prop.name}
                            </option>
                          );
                        })}
                      </Select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Nombre</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="first_name"
                        placeholder="Nombre"
                        value={first_name}
                        onChange={onChangeFirstName}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Apellido</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="last_name"
                        placeholder="Apellido"
                        value={last_name}
                        onChange={onChangeLastName}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="email"
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required, validEmail]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Nombre de usuario</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="user_name"
                        placeholder="Usuario"
                        value={user_name || ""}
                        onChange={onChangeUserName}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <button
                        className="btn btn-primary btn-fill pull-right"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Editar Usuario</span>
                      </button>
                    </div>

                    <CheckButton style={{ display: "none" }} ref={checkBtn} />

                    {/* <Button bsStyle="info" pullRight fill type="submit">
                        Crear Usuario
                      </Button> */}
                    <div className="clearfix" />
                  </Form>
                ) : (
                  <NotFound />
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UsersEdit;
