import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { /* status08Badge, */ statusBadge } from "../../utils/Badges";
import { purchaseStatus } from "../../variables/constants";
import { Comments } from "../../components/Comments";
import { purchaseComment } from "../../utils/Comments";
// import { status08 } from "../../variables/constants";

const Information = (props) => {
  const [information, setInformation] = useState(false);

  useEffect(() => {
    setInformation(props.data);
  }, [props.data]);

  // Standard - Todo aquello que sea una Consignación. (El dato sale de la pantalla de toma)
  // Prep NDD - (Todos aquellos que no sean consignación y aun no recibimos las cedulas, es decir que están en preparación.)
  // NDD - (Todos aquellos que ya están terminados y hemos recibido la cedulas)
  const calculateStatus = (info) => {
    let text;
    if (!info) {
      return "-";
    }
    if (!info.transaction_type_id) {
      return "Tipo De Operación sin definir";
    }
    if (info.transaction_type_id === 5) {
      text = "Standard";
    } else if (info.take_licence_date) {
      text = "NDD";
    } else {
      text = "Prep NDD";
    }
    return `${text}${
      info.diff_days
        ? ` (${info.diff_days} día${info.diff_days > 0 ? "s" : ""} hábil${
            info.diff_days > 0 ? "es" : ""
          })`
        : ""
    }`;
  };

  return (
    <div className="plate-information" style={{ width: "100%" }}>
      <Card
        ctTableFullWidth
        ctTableResponsive
        title="Información"
        content={
          props.loading ? (
            "Loading..."
          ) : (
            <>
              <Table striped hover>
                <tbody>
                  <tr>
                    <td>
                      <strong>Id</strong>
                    </td>
                    <td>
                      {information && information.vehicle_id
                        ? information.vehicle_id
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Dominio</strong>
                    </td>
                    <td>
                      {information && information.plate_number
                        ? information.plate_number
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Estado</strong>
                    </td>
                    <td>{calculateStatus(information)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Estado de la Toma</strong>
                    </td>
                    <td>
                      {information && information.status
                        ? statusBadge(information.status)
                        : "-"}
                    </td>
                  </tr>
                  {/* <tr>
                  <td><strong>Estado de Cedulas</strong></td>
                  <td>
                    {(information && information.take_licence_date)
                      ? (<>
                        <span>{moment(information.take_licence_date).format('DD/MM/YYYY')}</span>
                        {status08Badge(status08.RECEIVED.value)}
                      </>)
                      : '-'}
                  </td>
                </tr> */}
                  <tr>
                    <td>
                      <strong>Fecha Estimada Gestoria</strong>
                    </td>
                    <td>
                      {information && information.estimated_date
                        ? moment(information.estimated_date).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </td>
                  </tr>
                  {/* <tr>
                  <td><strong>Fecha Recepción</strong></td>
                  <td>
                    {(information && information.reception_date)
                      ? moment(information.reception_date).format('DD/MM/YYYY')
                      : '-'}
                  </td>
                </tr> */}
                </tbody>
              </Table>
              {information?.comments ? (
                <Comments
                  comments={purchaseComment(information?.comments)}
                  statusList={purchaseStatus}
                  hideReasons
                />
              ) : null}
            </>
          )
        }
      />
    </div>
  );
};

export default Information;
