import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import List from "components/Table/List.js";
import Content from "../../components/Modal/NotaryOffice/content";
import Modal from "../../layouts/ModalDisplay";
import NotaryService from "../../services/notary.service";
import AuthService from "../../services/auth.service";
import { constants } from "../../variables/constants";
import { useToast } from "../../hooks";
import { updateLocalStorageCache } from "utils/Service";

const headSort = [
  { text: "Id", field: "id", model: null },
  { text: "Nombre", field: "name", model: null },
  { text: "Dirección completa", field: null, model: null },
  { text: "localidad", field: null, model: null },
  { text: "", field: null, model: null },
];

const NotaryOfficesList = (props) => {
  const [show, setShow] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const addToast = useToast();

  const [body, setBody] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    model: null,
    field: "id",
    type: "ASC",
  });

  const handleSort = (model, field, type) => {
    setCurrentSort({ model, field, type });
  };

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("ESCRIBANIAS");
    setCurrentPermissions(permissions);

    const params = {
      sortField: currentSort?.field,
      sortType: currentSort?.type,
    };
    NotaryService.getAll(params).then((response) => {
      if (response.data.count > 0) {
        setBody(
          response.data.response.map((r) => ({
            id: r.id,
            name: r.name,
            address: r.address_complete,
            city: r.city_province,
          }))
        );
      }
      setTotalItems(response.data.count);
    });
  }, [currentSort]);

  const handleShowModal = (id, e) => {
    e.preventDefault();
    setDataModal({ id });
    setShow(true);
  };

  const handleCloseModal = () => setShow(false);

  const handleDelete = (id, e) => {
    e.preventDefault();
    const del = window.confirm(`¿Eliminar ${constants.notaryOffice}?`);
    if (del) {
      NotaryService.del(id).then(() => {
        let list = body.filter((data) => data.id !== parseInt(id));
        setBody(list);
        addToast({
          variant: "success",
          header: "Escribanias",
          body: "Se eliminó el lugar de firma correctamente",
        });
        updateLocalStorageCache();
      });
    }
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasUpdate ? (
          <a
            href={`/admin/notary-offices/${props.id}/edit`}
            className="btn-simple btn btn-xs btn-info"
          >
            <i className="fas fa-edit"></i>
          </a>
        ) : null}

        {currentPermissions && currentPermissions.permissions.hasUpdate ? (
          <Button
            onClick={(e) => handleShowModal(props.id, e)}
            className="btn-simple btn btn-xs btn-secondary"
          >
            <i className="fas fa-users-cog" aria-hidden="true"></i>
          </Button>
        ) : null}

        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <button
            onClick={(e) => handleDelete(props.id, e)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </button>
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <Container fluid>
        <Button
          href="/admin/notary-offices/create"
          className="btn-fill btn btn-primary capitalize"
        >
          Nueva {constants.notaryOffice}
        </Button>
        <br />
        <br />
        <List
          thead={headSort}
          tbody={body}
          totalItems={totalItems}
          actions={actions}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
        <Modal
          content={<Content data={dataModal} handleClose={handleCloseModal} />}
          showModal={show}
          handleShow={handleShowModal}
          handleClose={handleCloseModal}
        />
      </Container>
    </div>
  );
};

export default NotaryOfficesList;
