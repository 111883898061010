import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import { Badge, Button } from "react-bootstrap";

import AuditService from "../../../services/audit.service";
import ManagementService from "../../../services/management.service";

import Summary from "./summary";
import ConfirmationAlert from "../../ConfirmationAlert/confirmationAlert";
import { Comments } from "../../Comments";
import {
  constants,
  status08,
  purchaseStatus,
} from "../../../variables/constants";
import { useConfirmationAlert } from "../../../hooks";
import { purchaseComment } from "../../../utils/Comments";

const received08s = [
  status08.RECEIVED.value,
  status08.OBSERVED_RECEIVED.value,
  status08.COMPLETED.value,
];

const fieldsOptional = ["comment", "comment_reason_id", "final_payment_date"];

const Checklist = (props) => {
  const {
    data: {
      response: {
        vehicle_id,
        plate_number,
        createdAt,
        sdp,
        form_08,
        form_03,
        // certificate_of_title,
        green_licence,
        purchase_form,
        vpa,
        receipt_take,
        order_id,
        issuer_id,
        web_control_id,
        status: purchase_file_status,
        transaction_type_id,
        pledge,
        inspector,
        is_recived,
        vehicle_price,
        loss_request,
        final_payment_date,
        reception_place_id,
        canceled_status_id,
        restore_status,
        diff_days,
        issuer_entity_tentative_id,
        issuer_entity_tentative,
        issuer_entity_id,
        issuer_entity,
      },
    },
    handleModifiedStatus,
    handleModifiedFormsValidated,
    handleClose,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    createdAt,
    purchase_file_status,
    transaction_type_id,
    pledge,
    is_recived,
    vehicle_price,
    comment_reason_id: null,
    comment: null,
    loss_request,
    final_payment_date,
    reception_place_id,
    canceled_status_id,
    form_08: {
      ...form_08.purchase_file_form,
      has_form_08:
        form_08.validated ||
        received08s.includes(form_08.purchase_file_form.current_status),
    },
    form_03: {
      has_form_03: form_03.validated,
    },
    // certificate_of_title: {
    //   has_certificate_of_title: certificate_of_title.validated,
    // },
    green_licence: {
      has_green_licence: green_licence.validated,
    },
    purchase_form: {
      has_purchase_form: purchase_form.validated,
    },
    vpa: {
      has_vpa: vpa.validated,
    },
    receipt_take: {
      has_receipt_take: receipt_take.validated,
    },
    issuer_entity_tentative_id,
    issuer_entity_tentative,
    issuer_entity_id,
    issuer_entity,
  };

  // Campos de solo lectura para el popup
  const readOnlyfields = {
    vehicle_id,
    plate_number,
    sdp,
    inspector,
    is_recived,
    purchase_file_status,
    forms: {
      form_08,
      form_03,
      // certificate_of_title,
      green_licence,
      purchase_form,
      vpa,
    },
    diff_days,
  };

  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const { confirmationModal, handlePreConfirmationPurchaseModal } =
    useConfirmationAlert();
  const [comments, setComments] = useState([]);
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    AuditService.getHistory(vehicle_id, "comment_summary").then((response) => {
      setComments(response.data.response);
    });

    // Motivos de los comentarios
    ManagementService.getCommentReasons().then((response) => {
      if (response.data.success) {
        setReasons(
          response.data.response.filter((r) => !r.text.includes("[SUSPENDER]"))
        );
      }
    });
    // eslint-disable-next-line
  }, []);

  // Al presionar un botón
  useEffect(() => {
    // Validaciones de campos completos obligatorios
    const simplesFields =
      Object.entries(fields).filter(
        (f) =>
          !fieldsOptional.includes(f[0]) &&
          (f[1] === null || f[1] === undefined || f[1] === "")
      ).length === 0;
    const checkboxs =
      fields.is_recived &&
      fields.form_08.has_form_08 &&
      (form_03.purchase_file_form === false ||
        form_03.prontoform === false ||
        fields.form_03.has_form_03) &&
      (green_licence.purchase_file_form === false ||
        green_licence.prontoform === false ||
        fields.green_licence.has_green_licence) &&
      ((purchase_form.purchase_file_form &&
        (purchase_form.prontoform === false ||
          fields.purchase_form.has_purchase_form)) ||
        (purchase_form.purchase_file_form === false &&
          (vpa.purchase_file_form === false ||
            vpa.prontoform === false ||
            fields.vpa.has_vpa))) &&
      fields.receipt_take.has_receipt_take;
    const specialConditions =
      // !!fields.issuer_entity_tentative_id &&
      // fields.issuer_entity_tentative_id != "1" &&
      !!fields.issuer_entity_id &&
      fields.issuer_entity_id != "1";

    setAllowSubmit(simplesFields && checkboxs && specialConditions);

    if (submitAction) {
      const dataVehicle = {
        ...fields,
        vehicle_id,
      };
      // sacamos los campos que no son del tramite principal
      delete dataVehicle.form_08;
      delete dataVehicle.form_03;
      // delete (dataVehicle.certificate_of_title);
      delete dataVehicle.green_licence;
      delete dataVehicle.purchase_form;
      delete dataVehicle.vpa;
      delete dataVehicle.receipt_take;

      const forms = {
        vehicle_id,
        plate_number,
        form_08_id: form_08.purchase_file_form.id,
        has_form_08: fields.form_08.has_form_08,
        has_form_03: fields.form_03.has_form_03,
        // has_certificate_of_title: fields.certificate_of_title.has_certificate_of_title,
        has_green_licence: fields.green_licence.has_green_licence,
        has_purchase_form: fields.purchase_form.has_purchase_form,
        has_vpa: fields.vpa.has_vpa,
        has_receipt_take: fields.receipt_take.has_receipt_take,
      };

      handleModifiedStatus(dataVehicle);
      handleModifiedFormsValidated(forms);
      setSubmitAction(false);
      handleClose();
    }
  }, [
    submitAction,
    fields,
    plate_number,
    vehicle_id,
    form_03,
    form_08,
    green_licence,
    purchase_form,
    vpa,
    handleModifiedStatus,
    handleModifiedFormsValidated,
    handleClose,
  ]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  // Manejo generico del cambio de un dato complejo de un formulario, como ser un objeto
  const onComplexFieldChange = (primaryKey, valueKey, value) => {
    const { [primaryKey]: item } = fields;
    const field = { [primaryKey]: { ...item, [valueKey]: value } };
    setFields({
      ...fields,
      ...field,
    });
  };

  // Teórico: Aplica / No aplica
  const applyBadge = (criteria) =>
    criteria ? (
      <Badge bg="warning">{constants.apply}</Badge>
    ) : (
      <Badge bg="secondary">{constants.notAplly}</Badge>
    );

  // Prontoform: Recibido / No recibido
  const receivedBadge = (criteria) =>
    criteria ? (
      <Badge bg="success">{constants.received}</Badge>
    ) : (
      <Badge bg="secondary">{constants.notReceived}</Badge>
    );

  // Bloque de cada documento
  const itemBlock = (item) => (
    <div className="row">
      <div className="col">
        <strong className="capitalize">{item.title}</strong>
      </div>
      <div className="col">
        {typeof item.component.purchase_file_form === "boolean"
          ? applyBadge(item.component.purchase_file_form)
          : item.component.purchase_file_form}
      </div>
      <div className="col">
        {typeof item.component.prontoform === "boolean"
          ? receivedBadge(item.component.prontoform)
          : item.component.prontoform}
      </div>
      <div className="col">
        {item.component.purchase_file_form !== false ? (
          <input
            type="checkbox"
            name={item.checkbox.name}
            checked={item.checkbox.checked}
            onChange={(e) =>
              onComplexFieldChange(
                item.checkbox.name,
                `has_${item.checkbox.name}`,
                e.target.checked
              )
            }
          />
        ) : null}
      </div>
    </div>
  );

  const handleChangeStatus = (status_name, value) => {
    onSimpleFieldChange(status_name, value);
    setSubmitAction(true);
  };

  return (
    <div className="modalChecklist">
      <Summary
        fields={fields}
        readOnlyfields={readOnlyfields}
        onSimpleFieldChange={onSimpleFieldChange}
        onComplexFieldChange={onComplexFieldChange}
        setFields={setFields}
      />
      <div className="container">
        <Form>
          <div className="row">
            <div className="col">&nbsp;</div>
            <div className="col capitalize">
              <strong>{constants.theoretical}</strong>
            </div>
            <div className="col capitalize">
              <strong>{constants.prontoform}</strong>
            </div>
            <div className="col">
              <strong>{constants.received}</strong>
            </div>
          </div>
          {itemBlock({
            title: constants.form03,
            component: form_03,
            checkbox: {
              name: "form_03",
              checked: fields.form_03.has_form_03,
            },
          })}
          {/* {itemBlock({
            title: constants.certificateOfTitle,
            component: certificate_of_title,
            checkbox: {
              name: 'certificate_of_title',
              checked: fields.certificate_of_title.has_certificate_of_title,
            },
          })} */}
          {itemBlock({
            title: constants.greenLicence,
            component: { ...green_licence, purchase_file_form: true }, // Siemple aplica
            checkbox: {
              name: "green_licence",
              checked: fields.green_licence.has_green_licence,
            },
          })}
          {purchase_form.purchase_file_form
            ? itemBlock({
                title: constants.purchaseForm,
                component: purchase_form,
                checkbox: {
                  name: "purchase_form",
                  checked: fields.purchase_form.has_purchase_form,
                },
              })
            : itemBlock({
                title: constants.vpa,
                component: vpa,
                checkbox: {
                  name: "vpa",
                  checked: fields.vpa.has_vpa,
                },
              })}
          {itemBlock({
            title: constants.receiptTake,
            component: { purchase_file_form: "-", prontoform: "-" },
            checkbox: {
              name: "receipt_take",
              checked: fields.receipt_take.has_receipt_take,
            },
          })}
        </Form>
      </div>
      {issuer_id && order_id && web_control_id && (
        <>
          <hr />
          <div className="container">
            <div className="row">
              <div className="col">
                <a
                  className="badge badge-link download-certificate"
                  href={`${constants.url_certificate_of_title}/${issuer_id}/${order_id}/${web_control_id}?valid=false`}
                >
                  Descargar informe de dominio
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      <Form>
        <Comments
          reasons={reasons}
          comments={purchaseComment(comments)}
          statusList={purchaseStatus}
          onChangeSelect={(e) =>
            onSimpleFieldChange("comment_reason_id", e.target.value)
          }
          onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
        />
      </Form>
      <br />
      <div className="modal-footer">
        <div>
          <Button
            className="btn-fill pull-right btn btn-secundary btn-sm capitalize"
            onClick={() => setSubmitAction(true)}
          >
            {constants.update}
          </Button>
        </div>
        {purchase_file_status !== purchaseStatus.VALIDATION_EXCEPTION.value ? (
          <div className="row">
            <Button
              className="btn-fill pull-right btn btn-warning btn-sm capitalize"
              value={purchaseStatus.VALIDATION_EXCEPTION.value}
              onClick={(e) => {
                handlePreConfirmationPurchaseModal(
                  "purchase_file_status",
                  e.target.value,
                  purchaseStatus.VALIDATION_EXCEPTION.text,
                  handleChangeStatus
                );
              }}
            >
              {purchaseStatus.VALIDATION_EXCEPTION.text}
            </Button>
          </div>
        ) : null}
        <div className="row">
          <Button
            className="btn-fill pull-right btn btn-danger btn-sm capitalize"
            value={
              purchase_file_status !== purchaseStatus.PURCHASE_OBSERVED.value
                ? purchaseStatus.PURCHASE_OBSERVED.value
                : restore_status
            }
            onClick={(e) => {
              handlePreConfirmationPurchaseModal(
                "purchase_file_status",
                e.target.value,
                purchaseStatus[
                  purchase_file_status !==
                  purchaseStatus.PURCHASE_OBSERVED.value
                    ? "PURCHASE_OBSERVED"
                    : restore_status
                ].text,
                handleChangeStatus
              );
            }}
            disabled={
              !fields.comment?.length ||
              fields.comment_reason_id == null ||
              fields.comment_reason_id === constants.dropdownDefault
            }
          >
            {purchase_file_status !== purchaseStatus.PURCHASE_OBSERVED.value
              ? "Observar"
              : "Desobservar"}
          </Button>
        </div>
        <div>
          <Button
            className="btn-fill pull-right btn btn-primary btn-sm"
            value={
              purchase_file_status === purchaseStatus.PURCHASE_OBSERVED.value
                ? purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.value
                : purchaseStatus.FOLIO_IN_PROGRESS.value
            }
            onClick={(e) => {
              handlePreConfirmationPurchaseModal(
                "purchase_file_status",
                e.target.value,
                purchaseStatus[
                  purchase_file_status ===
                  purchaseStatus.PURCHASE_OBSERVED.value
                    ? "FOLIO_IN_PROGRESS_OBSERVED"
                    : "FOLIO_IN_PROGRESS"
                ].text,
                handleChangeStatus
              );
            }}
            disabled={!allowSubmit}
          >
            {constants.accept}
          </Button>
        </div>
      </div>
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default Checklist;
