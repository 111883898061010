import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getPrintForms = async () => {
  return await axios.get(`${API_URL}/print-forms`, { headers: authHeader() });
};

const logPrint = (payload) => {
  return axios
    .put(`${API_URL}/print`, { ...payload }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

export default {
  getPrintForms,
  logPrint,
};
