import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";

import { form08 } from "../../variables/constants";
import { useToast } from "../../hooks";
import CertificateService from "../../services/purchase/certificate.service";
import PdfViewer from "../../components/Modal/General/Pdf/PdfViewer";
import FormOptions from "../../components/Modal/PrintForms/formOptions";
import { formPDF } from "../../utils/pdf";

const initialFormState = {
  form: null,
  reception_place_id: null,
};

const PrintForms = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const addToast = useToast();
  const [fields, setFields] = useState(initialFormState);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const formatFields = (content) => {
    const make = content.vehicle.make.value.split("-")[1].trim();

    const owners = [];
    owners.push({
      holder_percentage: parseFloat(content.owner.holder_percentage.value),
      holder_name: content.owner.holder_name.value,
      marital_status: content.owner?.marital_status?.value,
      document_number: content.owner?.document_number?.value,
      document_type: content.owner?.document_type?.value,
      nationality: content.owner?.nationality?.value
    });
    if (!!content["owner-2"]) {
      owners.push({
        holder_percentage: parseFloat(
          content["owner-2"].holder_percentage.value
        ),
        holder_name: content["owner-2"].holder_name.value,
        marital_status: content["owner-2"]?.marital_status?.value,
        document_number: content["owner-2"]?.document_number?.value,
        document_type: content["owner-2"]?.document_type?.value,
        nationality: content["owner-2"]?.nationality?.value
      });
    }

    return {
      plate_number: content.vehicle.plate_number.value,
      car: {
        brand: make,
        model: content.vehicle.model.value.split("-")[1].trim(),
        type: content.vehicle.type.value.split("-")[1].trim(),
        use: content.vehicle.vehicle_use.value,
      },
      engine: {
        engineBrand: make,
        engineNumber: content.vehicle.engine_identification_number.value,
        chasisBrand: make,
        chasisNumber: content.vehicle.vehicle_identification_number.value,
      },
      owners,
    };
  };

  const onPrintRequest = (content) => {
    let data = null;
    if ([1, 2].includes(fields.form)) {
      data = fields.form === 1 ? form08.buyerInfo : form08.bacsInfo;
    }
    formPDF(
      data,
      formatFields(content),
      fields.form,
      fields.reception_place_id,
      66
    );
  };

  return (
    <div className="content">
      <FormOptions onSimpleFieldChange={onSimpleFieldChange} />
      <Form.Group controlId="formFileLg" className="m-3">
        <Form.Label>
          <strong>
            Seleccione un PDF para procesar (Informe de Dominio o Título)
          </strong>
        </Form.Label>
        <Form.Control
          type="file"
          size="lg"
          onChange={handleFileChange}
          accept="application/pdf"
        />
      </Form.Group>
      {selectedFile && (
        <PdfViewer
          file={selectedFile}
          data={props.data}
          onPress={onPrintRequest}
          onPressDisabled={!fields.form || !fields.reception_place_id}
        />
      )}
    </div>
  );
};

export default PrintForms;
